import { css } from 'lit';
/* eslint-disable max-lines */

import { QuantityType } from '@/config/ConfigQuantityTypes';
import { Color } from '@typings/shared-types';
import { FormSectionsRecord } from './ConfigREM';

// TODO: Have the API provide as many event type related configurations as possible to avoid possible desyncs between the data and the frontend config
// List of all event types returned by the events/fields endpoint
export const EventTypes = {
	ABANDONED_VEHICLE: 'ABANDONED VEHICLE',
	ALTERNATING_LANE_CLOSURES: 'ALTERNATING LANE CLOSURES',
	AMBER_ALERT: 'AMBER ALERT',
	ASSIST_MOTORIST: 'ASSIST MOTORIST',
	ASSIST_OTHER_AGENCY: 'ASSIST OTHER AGENCY',
	BRIDGE_MAINTENANCE: 'BRIDGE MAINTENANCE OPERATIONS',
	BROKEN_WATER_MAIN: 'BROKEN WATER MAIN',
	BUILDING_FIRE: 'BUILDING FIRE',
	COMMISSION_CRASH: 'COMMISSION CRASH',
	CONSTRUCTION: 'CONSTRUCTION',
	CRASH_FATAL: 'CRASH FATAL',
	CRASH_PD: 'CRASH PD',
	CRASH_PI: 'CRASH PI',
	CRASH_SITE_CLEANUP: 'CRASH SITE CLEANUP',
	DEBRIS_IN_THE_ROAD: 'DEBRIS IN THE ROAD',
	DETOUR: 'DETOUR',
	DOWNED_POWER_LINES: 'DOWNED POWER LINES',
	DUPLICATE_EVENT_NOT_USED: 'DUPLICATE EVENT/NOT USED',
	EOC_ACTIVATION: 'EOC ACTIVATION',
	FACILITY_CLOSURE: 'FACILITY CLOSURE',
	FESTIVAL: 'FESTIVAL',
	FIRE: 'FIRE',
	HAZARDOUS_MATERIALS_SPILL: 'HAZARDOUS MATERIALS SPILL',
	HEAVY_TRAFFIC: 'HEAVY TRAFFIC',
	HIGH_WATER: 'HIGH WATER',
	INCOMING_CALL: 'INCOMING CALL',
	JACKKNIFED_SEMI_TRAILER: 'JACKKNIFED SEMI TRAILER',
	LANE_BLOCKED: 'LANE BLOCKED',
	LOCAL_EVENT: 'LOCAL EVENT',
	MAINTENANCE: 'MAINTENANCE',
	MAINTENANCE_REQUEST: 'MAINTENANCE REQUEST',
	MEDICAL_EMERGENCY: 'MEDICAL EMERGENCY',
	OVERTURNED_SEMI_TRAILER: 'OVERTURNED SEMI TRAILER',
	MOBILE_MAINTENANCE_OPERATIONS: 'MOBILE MAINTENANCE OPERATIONS',
	PEDESTRIAN: 'PEDESTRIAN',
	POLICE_AT_SCENE: 'POLICE AT SCENE',
	RAMP_PARTIALLY_BLOCKED: 'RAMP PARTIALLY BLOCKED',
	SINGLE_LINE_TRAFFIC: 'SINGLE LINE TRAFFIC: ALTERNATING DIRECTIONS',
	SLIDE_OFF: 'SLIDE OFF',
	SLOW_MOVING_MAINTENANCE_VEHICLE: 'SLOW MOVING MAINTENANCE VEHICLE',
	SLOW_TRAFFIC: 'SLOW TRAFFIC',
	SPECIAL_DETAIL: 'SPECIAL DETAIL',
	SPILLED_LOAD: 'SPILLED LOAD',
	STALLED_VEHICLE: 'STALLED VEHICLE',
	SUPERLOAD: 'SUPERLOAD',
	TRAFFIC_HAZARD: 'TRAFFIC HAZARD',
	TRAINING: 'TRAINING',
	TROUBLE_TICKET: 'TROUBLE TICKET',
	VEHICLE_FIRE: 'VEHICLE FIRE',
} as const;

enum RespondingUnitType {
	FIRE_DEPARTMENT = 'FIRE DEPARTMENT',
	EMS = 'EMS, EMERGENCY, MEDICAL SERVICES',
	CORONER = 'CORONER, MEDICAL, CORONER',
	CONSTRUCTION = 'CONSTRUCTION',
	ISP = 'ISP, STATE POLICE',
	HEALTH_DEPT = 'HEALTH DEPT',
	HAZMAT_RESPONSE = 'HAZMAT RESPONSE',
	BRIDGE = 'BRIDGE, INDOT, BRIDGE INSPECTOR',
	HOOSIER_HELPER = 'HOOSIER HELPER',
	VINCENNES_DISTRICT = 'VINCENNES DISTRICT',
	GREENFIELD_DISTRICT = 'GREENFIELD DISTRICT',
	CRAWFORDSVILLE_DISTRICT = 'CRAWFORDSVILLE DISTRICT',
	TMC_DISPATCHER = 'TMC DISPATCHER',
	SEYMOUR_DISTRICT = 'SEYMOUR DISTRICT',
	MEDEVAC = 'MEDEVAC/LIFELINE AIR AMBULANCE',
	MAINTENANCE = 'MAINTENANCE',
	SPILL_RECOVERY = 'SPILL, RECOVERY',
	SIGNAL_TECH = 'SIGNAL, SIGNAL, TECH',
	LOCAL_CITY_COUNTY_POLICE = 'LOCAL CITY/COUNTY POLICE',
	PENDING_UNIT = 'PENDING UNIT',
	FT_WAYNE_DISTRICT = 'FT WAYNE DISTRICT',
	LAPORTE_DISTRICT = 'LAPORTE DISTRICT',
	ATIS_ADMINISTRATOR = 'ATIS ADMINISTRATOR/CUSTOM MESSAGE',
	WRECKER = 'WRECKER',
}

enum RespondingUnitDispositionType {
	AMA = 'AMA - AUTOMATED MESSAGE ACTIVATION',
	AVT = 'AVT - ABANDONED VEHICLE TAGGED',
	CMO = 'CMO - CALLED TO MAKE OWN ARRANGEMENTS',
	CMS = 'CMS - CUSTOM MESSAGE SCHEDULED (NOT AUTOMATED)',
	DCC = 'DCC - DUPLICATED CARD CANCELLED',
	DDO = 'DDO - DISREGARDED, MOTORIST DROVE OFF',
	DFV = 'DFV - DISPATCHED FOR FIELD VERIFICATION',
	DIO = 'DIO - DISPATCHED FOR INFORMATION ONLY',
	DNN = 'DNN - DISREGARD, NOT NEEDED',
	DNR = 'DNR - DID NOT RESPOND',
	DOS = 'DOS - MOTORIST DROVE OFF WHILE TECH ON-SITE',
	EEI = 'EEI - ERRANT ENTRY IGNORE',
	EFR = 'EFR - ESCORTED FROM ROADWAY',
	EMP = 'EMP - EMERGENCY PATCHING',
	FAA = 'FAA - FIRST AID ADMINISTERED',
	FMD = 'FMD - FUEL MOTORIST VEHICLE (DIESEL)',
	FMG = 'FMG - FUEL MOTORIST VEHICLE (GAS)',
	FSP = 'FSP - FIRE SUPPRESSION',
	HAZ = 'HAZ - HAZARDOUS RESPONSE OR CONTAINMENT / OIL DRY',
	INF = 'INF - INFORMATION / DIRECTIONS GIVEN',
	JMP = 'JMP - JUMP STARTED VEHICLE',
	MCP = 'MCP - MOTORIST ON CELL PHONE',
	MVR = 'MVR - MINOR VEHICLE REPAIR',
	NPS = 'NPS - NOTIFICATION PAGE SENT',
	NRE = 'NRE - NO REPORT (ONLY OBSERVED) EVENT (IN PROGRESS)',
	OTR = 'OTR - OTHER DISPOSITON',
	RAS = 'RAS - REFUSED ASSISTANCE',
	RIS = 'RIS - REMOVED ILLEGAL SIGN',
	RRW = 'RRW - RELOCATED TO / FROM ROADWAY',
	RTA = 'RTA - REFERRED TO ANOTHER AGENCY',
	RTD = 'RTD - REFERRED TO TECH DEPLOYMENT DIVISION',
	RTK = 'RTK - REPORT TAKEN',
	RXS = 'RXS - REQUESTED EXTERNAL SERVICES',
	SLD = 'SLD - SECURE LOAD',
	SMD = 'SMD - SLEEPING MOTORIST DROVE OFF',
	TCH = 'TCH - TIRE CHANGE',
	TFC = 'TFC - TRAFFIC CONTROL',
	TOC = 'TOC - TOWED FROM SCENE, OWNER CALLED',
	TPC = 'TPC - TOWED FROM SCENE, POLICE CALLED',
	TRN = 'TRN - TRAINING EVENT (PROVIDING OR RECEIVING)',
	UTL = 'UTL - UNABLE TO LOCATE',
	WCK = 'WCK - WELFARE CHECK',
	WPV = 'WPV - WATER / COOLANT PROVIDED IN VEHICLE',
}

export type EventType = typeof EventTypes[keyof typeof EventTypes];

interface EventTypeConfigOptions {
	importTo511: boolean;
	color: Color | null;
	restriction: QuantityType | null;
	defaultFormSections: Partial<FormSectionsRecord<boolean>>;
	respondingUnitSuggestions: Record<string, string[]>;
}

// example empty config entry:
// [EventTypes.NEW_EVENT_TYPE]: {
// 		importTo511: false,
// 		color: null,
// 		restriction: null,
// 		defaultFormSections: {
// 			DMS: false,
// 			VEHICLES: false,
// 			RESPONDING_UNITS: false,
// 		},
// 		respondingUnitSuggestions: {},
// }
const NON_HEADLINE_PHRASE_COLOR: Color = '#1267e4';

export const EventTypeConfig: Partial<Record<EventType, EventTypeConfigOptions>> = {
	[EventTypes.ABANDONED_VEHICLE]: {
		importTo511: false,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: false,
			VEHICLES: true,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {
			[RespondingUnitType.HOOSIER_HELPER]: [
				RespondingUnitDispositionType.AVT,
				RespondingUnitDispositionType.DFV,
				RespondingUnitDispositionType.EMP,
				RespondingUnitDispositionType.TFC,
			],
			[RespondingUnitType.ISP]: [
				RespondingUnitDispositionType.RRW,
				RespondingUnitDispositionType.TFC,
			],
			[RespondingUnitType.WRECKER]: [
				RespondingUnitDispositionType.TOC,
				RespondingUnitDispositionType.TPC,
			],
		},
	},
	[EventTypes.ALTERNATING_LANE_CLOSURES]: {
    importTo511: false,
    color: NON_HEADLINE_PHRASE_COLOR,
    restriction: false,
    defaultFormSections: {
      DMS: false,
      VEHICLES: false,
      RESPONDING_UNITS: false,
    },
    respondingUnitSuggestions: {},
  },
	[EventTypes.AMBER_ALERT]: {
		importTo511: false,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: false,
			VEHICLES: false,
			RESPONDING_UNITS: false,
		},
		respondingUnitSuggestions: {},
	},
	[EventTypes.ASSIST_MOTORIST]: {
		importTo511: false,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: false,
			VEHICLES: true,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {
			[RespondingUnitType.HOOSIER_HELPER]: [
				RespondingUnitDispositionType.CMO,
				RespondingUnitDispositionType.DDO,
				RespondingUnitDispositionType.DNN,
				RespondingUnitDispositionType.DNR,
				RespondingUnitDispositionType.DOS,
				RespondingUnitDispositionType.EFR,
				RespondingUnitDispositionType.EMP,
				RespondingUnitDispositionType.FMD,
				RespondingUnitDispositionType.FMG,
				RespondingUnitDispositionType.INF,
				RespondingUnitDispositionType.JMP,
				RespondingUnitDispositionType.MCP,
				RespondingUnitDispositionType.MVR,
				RespondingUnitDispositionType.OTR,
				RespondingUnitDispositionType.RAS,
				RespondingUnitDispositionType.RRW,
				RespondingUnitDispositionType.SLD,
				RespondingUnitDispositionType.SMD,
				RespondingUnitDispositionType.TCH,
				RespondingUnitDispositionType.TFC,
				RespondingUnitDispositionType.TOC,
				RespondingUnitDispositionType.TPC,
				RespondingUnitDispositionType.UTL,
				RespondingUnitDispositionType.WCK,
				RespondingUnitDispositionType.WPV,
			],
			[RespondingUnitType.ISP]: [RespondingUnitDispositionType.DDO],
			[RespondingUnitType.TMC_DISPATCHER]: [RespondingUnitDispositionType.DDO],
			[RespondingUnitType.WRECKER]: [
				RespondingUnitDispositionType.TPC,
				RespondingUnitDispositionType.TOC,
			],
			[RespondingUnitType.PENDING_UNIT]:[],
		},
	},
	[EventTypes.ASSIST_OTHER_AGENCY]: {
		importTo511: false,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: false,
			VEHICLES: false,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {
			[RespondingUnitType.HOOSIER_HELPER]: [
				RespondingUnitDispositionType.DFV,
				RespondingUnitDispositionType.DNN,
				RespondingUnitDispositionType.TFC,
			],
		},
	},
	[EventTypes.BRIDGE_MAINTENANCE]: {
    importTo511: false,
    color: NON_HEADLINE_PHRASE_COLOR,
    restriction: false,
    defaultFormSections: {
      DMS: false,
      VEHICLES: false,
      RESPONDING_UNITS: false,
    },
    respondingUnitSuggestions: {},
  },
	[EventTypes.BROKEN_WATER_MAIN]: {
    importTo511: false,
    color: NON_HEADLINE_PHRASE_COLOR,
    restriction: false,
    defaultFormSections: {
      DMS: false,
      VEHICLES: false,
      RESPONDING_UNITS: false,
    },
    respondingUnitSuggestions: {},
  },
	[EventTypes.BUILDING_FIRE]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: false,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {
			[RespondingUnitType.HOOSIER_HELPER]: [
				RespondingUnitDispositionType.EMP,
				RespondingUnitDispositionType.FSP,
				RespondingUnitDispositionType.TFC,
			],
			[RespondingUnitType.FIRE_DEPARTMENT]: [
				RespondingUnitDispositionType.FSP,
				RespondingUnitDispositionType.TFC,
			],
			[RespondingUnitType.ISP]: [
				RespondingUnitDispositionType.RTK,
				RespondingUnitDispositionType.NRE,
			],
			[RespondingUnitType.WRECKER]: [
				RespondingUnitDispositionType.TPC,
				RespondingUnitDispositionType.TOC,
			],
		},
	},
	[EventTypes.COMMISSION_CRASH]: {
		importTo511: false,
		color: null,
		restriction: null,
		defaultFormSections: {},
		respondingUnitSuggestions: {},
	},
	[EventTypes.CONSTRUCTION]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: false,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {
			CONSTRUCTION: [
				RespondingUnitDispositionType.AMA,
				RespondingUnitDispositionType.DIO,
				RespondingUnitDispositionType.CMS,
			],
		},
	},
	[EventTypes.CRASH_FATAL]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: true,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {
			[RespondingUnitType.HOOSIER_HELPER]: [
				RespondingUnitDispositionType.EMP,
				RespondingUnitDispositionType.FAA,
				RespondingUnitDispositionType.HAZ,
				RespondingUnitDispositionType.NRE,
				RespondingUnitDispositionType.RRW,
				RespondingUnitDispositionType.TFC,
			],
			[RespondingUnitType.CORONER]: [RespondingUnitDispositionType.RTK],
			[RespondingUnitType.EMS]: [RespondingUnitDispositionType.FAA],
			[RespondingUnitType.FIRE_DEPARTMENT]: [
				RespondingUnitDispositionType.FAA,
				RespondingUnitDispositionType.FSP,
				RespondingUnitDispositionType.TFC,
			],
			[RespondingUnitType.ISP]: [RespondingUnitDispositionType.RTK],
			[RespondingUnitType.LOCAL_CITY_COUNTY_POLICE]: [RespondingUnitDispositionType.RTK],
			[RespondingUnitType.MAINTENANCE]: [RespondingUnitDispositionType.TFC],
			[RespondingUnitType.WRECKER]: [RespondingUnitDispositionType.TPC],
		},
	},
	[EventTypes.CRASH_PD]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: true,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {
			[RespondingUnitType.HOOSIER_HELPER]: [
				RespondingUnitDispositionType.DFV,
				RespondingUnitDispositionType.DNN,
				RespondingUnitDispositionType.EMP,
				RespondingUnitDispositionType.RRW,
				RespondingUnitDispositionType.TFC,
			],
			[RespondingUnitType.ISP]: [RespondingUnitDispositionType.RTK],
			[RespondingUnitType.LOCAL_CITY_COUNTY_POLICE]: [RespondingUnitDispositionType.RTK],
			[RespondingUnitType.WRECKER]: [
				RespondingUnitDispositionType.TOC,
				RespondingUnitDispositionType.TPC,
			],
		},
	},
	[EventTypes.CRASH_PI]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: true,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {
			[RespondingUnitType.HOOSIER_HELPER]: [
				RespondingUnitDispositionType.DFV,
				RespondingUnitDispositionType.DNN,
				RespondingUnitDispositionType.EMP,
				RespondingUnitDispositionType.FAA,
				RespondingUnitDispositionType.RRW,
				RespondingUnitDispositionType.TFC,
			],
			[RespondingUnitType.EMS]: [RespondingUnitDispositionType.FAA],
			[RespondingUnitType.FIRE_DEPARTMENT]: [
				RespondingUnitDispositionType.FAA,
				RespondingUnitDispositionType.FSP,
				RespondingUnitDispositionType.TFC,
			],
			[RespondingUnitType.ISP]: [RespondingUnitDispositionType.RTK],
			[RespondingUnitType.LOCAL_CITY_COUNTY_POLICE]: [RespondingUnitDispositionType.RTK],
			[RespondingUnitType.WRECKER]: [
				RespondingUnitDispositionType.TOC,
				RespondingUnitDispositionType.TPC,
			],
		},
	},
	[EventTypes.CRASH_SITE_CLEANUP]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: false,
			VEHICLES: false,
			RESPONDING_UNITS: false,
		},
		respondingUnitSuggestions: {},
	},
	[EventTypes.DEBRIS_IN_THE_ROAD]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: false,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {
			[RespondingUnitType.HOOSIER_HELPER]: [
				RespondingUnitDispositionType.DFV,
				RespondingUnitDispositionType.DNN,
				RespondingUnitDispositionType.EMP,
				RespondingUnitDispositionType.RRW,
				RespondingUnitDispositionType.RTA,
				RespondingUnitDispositionType.TFC,
				RespondingUnitDispositionType.UTL,
			],
			[RespondingUnitType.ISP]: [RespondingUnitDispositionType.RRW],
		},
	},
	[EventTypes.DETOUR]: {
    importTo511: false,
    color: NON_HEADLINE_PHRASE_COLOR,
    restriction: false,
    defaultFormSections: {
      DMS: false,
      VEHICLES: false,
      RESPONDING_UNITS: false,
    },
    respondingUnitSuggestions: {},
  },
	[EventTypes.DOWNED_POWER_LINES]: {
    importTo511: false,
    color: NON_HEADLINE_PHRASE_COLOR,
    restriction: false,
    defaultFormSections: {
      DMS: false,
      VEHICLES: false,
      RESPONDING_UNITS: false,
    },
    respondingUnitSuggestions: {},
  },
	[EventTypes.DUPLICATE_EVENT_NOT_USED]: {
		importTo511: false,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: false,
			VEHICLES: false,
			RESPONDING_UNITS: false,
		},
		respondingUnitSuggestions: {},
	},
	[EventTypes.EOC_ACTIVATION]: {
		importTo511: false,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: false,
			VEHICLES: false,
			RESPONDING_UNITS: false,
		},
		respondingUnitSuggestions: {},
	},
	[EventTypes.FACILITY_CLOSURE]: {
		importTo511: false,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: false,
			VEHICLES: false,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {},
	},
	[EventTypes.FESTIVAL]: {
    importTo511: false,
    color: NON_HEADLINE_PHRASE_COLOR,
    restriction: false,
    defaultFormSections: {
      DMS: false,
      VEHICLES: false,
      RESPONDING_UNITS: false,
    },
    respondingUnitSuggestions: {},
  },
	[EventTypes.FIRE]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: false,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {
			[RespondingUnitType.HOOSIER_HELPER]: [
				RespondingUnitDispositionType.EMP,
				RespondingUnitDispositionType.FSP,
				RespondingUnitDispositionType.TFC,
			],
			[RespondingUnitType.FIRE_DEPARTMENT]: [
				RespondingUnitDispositionType.FSP,
				RespondingUnitDispositionType.TFC,
			],
			[RespondingUnitType.ISP]: [
				RespondingUnitDispositionType.RTK,
				RespondingUnitDispositionType.NRE,
			],
			[RespondingUnitType.WRECKER]: [
				RespondingUnitDispositionType.TPC,
				RespondingUnitDispositionType.TOC,
			],
		},
	},
	[EventTypes.HAZARDOUS_MATERIALS_SPILL]: {
    importTo511: false,
    color: NON_HEADLINE_PHRASE_COLOR,
    restriction: false,
    defaultFormSections: {
      DMS: false,
      VEHICLES: false,
      RESPONDING_UNITS: false,
    },
    respondingUnitSuggestions: {},
  },
	[EventTypes.HEAVY_TRAFFIC]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: false,
			RESPONDING_UNITS: false,
		},
		respondingUnitSuggestions: {},
	},
	[EventTypes.HIGH_WATER]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: false,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {},
	},
	[EventTypes.INCOMING_CALL]: {
		importTo511: false,
		color: null,
		restriction: false,
		defaultFormSections: {
			DMS: false,
			VEHICLES: false,
			RESPONDING_UNITS: false,
		},
		respondingUnitSuggestions: {},
	},
	[EventTypes.JACKKNIFED_SEMI_TRAILER]: {
    importTo511: false,
    color: NON_HEADLINE_PHRASE_COLOR,
    restriction: false,
    defaultFormSections: {
      DMS: false,
      VEHICLES: false,
      RESPONDING_UNITS: false,
    },
    respondingUnitSuggestions: {},
  },
	[EventTypes.LANE_BLOCKED]: {
    importTo511: false,
    color: NON_HEADLINE_PHRASE_COLOR,
    restriction: false,
    defaultFormSections: {
      DMS: false,
      VEHICLES: false,
      RESPONDING_UNITS: false,
    },
    respondingUnitSuggestions: {},
  },
	[EventTypes.LOCAL_EVENT]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: false,
			RESPONDING_UNITS: false,
		},
		respondingUnitSuggestions: {},
	},
	[EventTypes.MAINTENANCE]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: false,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {
			[RespondingUnitType.MAINTENANCE]: [
				RespondingUnitDispositionType.AMA,
				RespondingUnitDispositionType.DIO,
				RespondingUnitDispositionType.CMS,
			],
		},
	},
	[EventTypes.MAINTENANCE_REQUEST]: {
		importTo511: false,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: false,
			VEHICLES: false,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {},
	},
	[EventTypes.MEDICAL_EMERGENCY]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: true,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {
			[RespondingUnitType.HOOSIER_HELPER]: [
				RespondingUnitDispositionType.EMP,
				RespondingUnitDispositionType.FAA,
				RespondingUnitDispositionType.TFC,
			],
			[RespondingUnitType.EMS]: [RespondingUnitDispositionType.FAA],
			[RespondingUnitType.FIRE_DEPARTMENT]: [
				RespondingUnitDispositionType.FAA,
				RespondingUnitDispositionType.FSP,
				RespondingUnitDispositionType.TFC,
			],
			[RespondingUnitType.ISP]: [RespondingUnitDispositionType.RTK],
			[RespondingUnitType.WRECKER]: [
				RespondingUnitDispositionType.TOC,
				RespondingUnitDispositionType.TPC,
			],
		},
	},
	[EventTypes.MOBILE_MAINTENANCE_OPERATIONS]: {
    importTo511: false,
    color: NON_HEADLINE_PHRASE_COLOR,
    restriction: false,
    defaultFormSections: {
      DMS: false,
      VEHICLES: false,
      RESPONDING_UNITS: false,
    },
    respondingUnitSuggestions: {},
  },
	[EventTypes.OVERTURNED_SEMI_TRAILER]: {
    importTo511: false,
    color: NON_HEADLINE_PHRASE_COLOR,
    restriction: false,
    defaultFormSections: {
      DMS: false,
      VEHICLES: false,
      RESPONDING_UNITS: false,
    },
    respondingUnitSuggestions: {},
  },
	[EventTypes.PEDESTRIAN]: {
		importTo511: false,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: false,
			VEHICLES: false,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {
			[RespondingUnitType.HOOSIER_HELPER]: [
				RespondingUnitDispositionType.DFV,
				RespondingUnitDispositionType.EFR,
				RespondingUnitDispositionType.EMP,
				RespondingUnitDispositionType.INF,
				RespondingUnitDispositionType.RTA,
			],
		},
	},
	[EventTypes.POLICE_AT_SCENE]: {
    importTo511: false,
    color: NON_HEADLINE_PHRASE_COLOR,
    restriction: false,
    defaultFormSections: {
      DMS: false,
      VEHICLES: false,
      RESPONDING_UNITS: false,
    },
    respondingUnitSuggestions: {},
  },
	[EventTypes.RAMP_PARTIALLY_BLOCKED]: {
    importTo511: false,
    color: NON_HEADLINE_PHRASE_COLOR,
    restriction: false,
    defaultFormSections: {
      DMS: false,
      VEHICLES: false,
      RESPONDING_UNITS: false,
    },
    respondingUnitSuggestions: {},
  },
	[EventTypes.SINGLE_LINE_TRAFFIC]: {
    importTo511: false,
    color: NON_HEADLINE_PHRASE_COLOR,
    restriction: false,
    defaultFormSections: {
      DMS: false,
      VEHICLES: false,
      RESPONDING_UNITS: false,
    },
    respondingUnitSuggestions: {},
  },
	[EventTypes.SLIDE_OFF]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: true,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {
			[RespondingUnitType.HOOSIER_HELPER]: [
				RespondingUnitDispositionType.DFV,
				RespondingUnitDispositionType.DNN,
				RespondingUnitDispositionType.EMP,
				RespondingUnitDispositionType.RRW,
				RespondingUnitDispositionType.RTA,
				RespondingUnitDispositionType.TFC,
				RespondingUnitDispositionType.WCK,
			],
			[RespondingUnitType.ISP]: [RespondingUnitDispositionType.RTK],
			[RespondingUnitType.WRECKER]: [
				RespondingUnitDispositionType.TOC,
				RespondingUnitDispositionType.TPC,
			],
		},
	},
	[EventTypes.SLOW_MOVING_MAINTENANCE_VEHICLE]: {
    importTo511: false,
    color: NON_HEADLINE_PHRASE_COLOR,
    restriction: false,
    defaultFormSections: {
      DMS: false,
      VEHICLES: false,
      RESPONDING_UNITS: false,
    },
    respondingUnitSuggestions: {},
  },
	[EventTypes.SLOW_TRAFFIC]: {
		importTo511: false,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: false,
			RESPONDING_UNITS: false,
		},
		respondingUnitSuggestions: {},
	},
	[EventTypes.SPECIAL_DETAIL]: {
		importTo511: false,
		color: null,
		restriction: null,
		defaultFormSections: {},
		respondingUnitSuggestions: {},
	},
	[EventTypes.SPILLED_LOAD]: {
    importTo511: false,
    color: NON_HEADLINE_PHRASE_COLOR,
    restriction: false,
    defaultFormSections: {
      DMS: false,
      VEHICLES: false,
      RESPONDING_UNITS: false,
    },
    respondingUnitSuggestions: {},
  },
	[EventTypes.STALLED_VEHICLE]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: true,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {
			[RespondingUnitType.HOOSIER_HELPER]: [
				RespondingUnitDispositionType.EMP,
				RespondingUnitDispositionType.JMP,
				RespondingUnitDispositionType.MVR,
				RespondingUnitDispositionType.RRW,
				RespondingUnitDispositionType.TFC,
			],
			[RespondingUnitType.ISP]: [RespondingUnitDispositionType.RTK],
			[RespondingUnitType.WRECKER]: [
				RespondingUnitDispositionType.TOC,
				RespondingUnitDispositionType.TPC,
			],
		},
	},
	[EventTypes.SUPERLOAD]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: false,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {},
	},
	[EventTypes.TRAFFIC_HAZARD]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: true,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {},
	},
	[EventTypes.TRAINING]: {
		importTo511: false,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: false,
			VEHICLES: true,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {},
	},
	[EventTypes.TROUBLE_TICKET]: {
		importTo511: false,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: false,
			VEHICLES: false,
			RESPONDING_UNITS: false,
		},
		respondingUnitSuggestions: {},
	},
	[EventTypes.VEHICLE_FIRE]: {
		importTo511: true,
		color: null,
		restriction: null,
		defaultFormSections: {
			DMS: true,
			VEHICLES: false,
			RESPONDING_UNITS: true,
		},
		respondingUnitSuggestions: {
			[RespondingUnitType.HOOSIER_HELPER]: [
				RespondingUnitDispositionType.EMP,
				RespondingUnitDispositionType.FSP,
				RespondingUnitDispositionType.TFC,
			],
			[RespondingUnitType.FIRE_DEPARTMENT]: [
				RespondingUnitDispositionType.FSP,
				RespondingUnitDispositionType.TFC,
			],
			[RespondingUnitType.ISP]: [
				RespondingUnitDispositionType.RTK,
				RespondingUnitDispositionType.NRE,
			],
			[RespondingUnitType.WRECKER]: [
				RespondingUnitDispositionType.TPC,
				RespondingUnitDispositionType.TOC,
			],
		},
	},
};
