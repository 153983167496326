import { css } from 'lit';
/* eslint-disable max-lines */
import {
	ChangeSetItem,
	EventStatus,
	LaneBlockage,
	LaneImpactValue,
	RecordStatus,
	RespondingUnitMember,
} from '../../typings/api';
import { TimelineDayEntryChange } from '../../typings/shared-types';
import { LOCALE } from '../constants';
import {
	isLaneImpacted,
	isValidNumber,
	isValidString,
	numberIsValidAndFinite,
} from '../utils/utils';
import { DebuggingConfig } from './ConfigCARSx';
import { CARS_EVENT_INTEGRATION_ENABLED, ConfigREMEventForm } from './ConfigREM';

export const UNKNOWN_USER = 'System';

const laneBlockageToTimelineEntry = (
	laneBlockage?: LaneBlockage,
	oldLaneBlockage?: LaneBlockage,
): string => {
	if (isLaneImpacted(laneBlockage) && laneBlockage) {
		const lanesAffected: number[] = [...(laneBlockage.lanesAffected ?? [])].sort();
		const numLanes = lanesAffected.length;
		const impactMessages = [];
		if (numLanes > 0) {
			impactMessages.push(
				`Lane${numLanes > 1 ? 's' : ''} ${
					numLanes > 1
						? `${lanesAffected.slice(0, numLanes - 1).join(', ')} and ${
								lanesAffected[numLanes - 1]
						  }`
						: lanesAffected[0]
				}`,
			);
		}
		if (laneBlockage.insideShoulderAffected) {
			impactMessages.push('inside shoulder');
		}
		if (laneBlockage.outsideShoulderAffected) {
			impactMessages.push('outside shoulder');
		}
		if (laneBlockage.entranceRampAffected) {
			impactMessages.push('entrance ramp');
		}
		if (laneBlockage.exitRampAffected) {
			impactMessages.push('exit ramp');
		}
		if (laneBlockage.allLanesAffected) {
			impactMessages.push('Affected direction added');
		}
		if (oldLaneBlockage?.allLanesAffected && laneBlockage?.allLanesAffected === false) {
			impactMessages.push('Affected direction removed');
		}
		return impactMessages.join(', ');
	}
	if (oldLaneBlockage?.allLanesAffected && laneBlockage?.allLanesAffected === false) {
		return 'Affected direction removed';
	}
	return 'No lanes impacted';
};

export const resolveChangeSetItemToTimelineDayEntryChange = (
	changeSetName: string,
	changeSetItem: ChangeSetItem,
	timeStamp: number,
): TimelineDayEntryChange | undefined => {
	if (changeSetName === 'signs') {
		switch (changeSetItem.fieldName) {
			case 'Added':
				return {
					subject: 'DMS Added',
					description: changeSetItem.value as string,
					highlighted: false,
				};
			case 'Removed':
				return {
					subject: 'DMS Removed',
					description: changeSetItem.value as string,
					highlighted: false,
				};
			default:
				if (DebuggingConfig.showConsoleLogs) {
					console.warn(`unexpected '${changeSetName}' field name:`, changeSetItem);
				}
		}
	}
	switch (changeSetItem.fieldName) {
		case 'mileStart':
			return {
				subject: 'Mile Marker Start',
				description: (changeSetItem.value as number)?.toFixed(1)?.toString() ?? `Removed`,
				highlighted: false,
			};
		case 'mileEnd':
			return {
				subject: 'Mile Marker End',
				description: (changeSetItem.value as number)?.toFixed(1)?.toString() ?? `Removed`,
				highlighted: false,
			};
		case 'licensePlate':
			return {
				subject: 'Vehicle License Plate',
				description:
					(changeSetItem.value as string).replace(
						ConfigREMEventForm.vehicles.licensePlateNumberDelimitter,
						' - ',
					) ?? `Removed`,
				highlighted: false,
			};
		case 'description':
			return {
				subject: 'Vehicle Description',
				description: (changeSetItem.value as string) ?? `Removed`,
				highlighted: false,
			};
		case 'priority':
			return {
				subject: 'Priority',
				description: (changeSetItem.value as number)?.toString() ?? `Removed`,
				highlighted: false,
			};
		case 'routeDesignator':
			return {
				subject: 'Route',
				description: (changeSetItem.value as number)?.toString() ?? `Removed`,
				highlighted: false,
			};
		case 'county': {
			const values = changeSetItem.value as string[];
			return {
				subject: `Count${values.length === 1 ? `y` : `ies`}`,
				description: values.length ? values.join(', ') : 'N/A',
				highlighted: false,
			};
		}
		case 'district': {
			const values = changeSetItem.value as string[];
			return {
				subject: `District${values.length === 1 ? `` : `s`}`,
				description: values.length ? values.join(', ') : 'N/A',
				highlighted: false,
			};
		}
		case 'subdistrict': {
			const values = changeSetItem.value as string[];
			return {
				subject: `Sub-District${values.length === 1 ? `` : `s`}`,
				description: values.length ? values.join(', ') : 'N/A',
				highlighted: false,
			};
		}
		case 'unit': {
			const values = changeSetItem.value as string[];
			return {
				subject: `Unit${values.length === 1 ? `` : `s`}`,
				description: values.length ? values.join(', ') : 'N/A',
				highlighted: false,
			};
		}
		case 'eventType':
			return {
				subject: 'Headline',
				description: (changeSetItem.value as string) ?? `Removed`,
				highlighted: false,
			};
		case 'eventSource':
			return {
				subject: 'Event Source',
				description: (changeSetItem.value as string) ?? `Removed`,
				highlighted: false,
			};
		case 'reporterName':
			return {
				subject: 'Source Name',
				description: (changeSetItem.value as string) ?? 'Removed',
				highlighted: false,
			};
		case 'reporterPhoneNumber':
			return {
				subject: 'Source Phone Number',
				description: (changeSetItem.value as string) ?? 'Removed',
				highlighted: false,
			};
		case 'verified':
			return {
				subject: 'Visual Verification',
				description: `${
					isValidNumber(changeSetItem.value)
						? `Verified on ${new Date(changeSetItem.value).toLocaleString(
								LOCALE,
								ConfigREMEventForm.wholeDatestampFormatOptions,
						  )}`
						: `Unverified`
				} by user "${changeSetItem.createdBy as string}"`,
				highlighted: false,
			};
		case 'eventStart': {
			const startTime: number = numberIsValidAndFinite(changeSetItem.value)
				? changeSetItem.value
				: NaN;
			return {
				subject: startTime < new Date().getTime() ? 'Event Started' : 'Scheduled Start',
				description: numberIsValidAndFinite(changeSetItem.value)
					? `${new Date(changeSetItem.value).toLocaleString(
							LOCALE,
							ConfigREMEventForm.wholeDatestampFormatOptions,
					  )}`
					: 'Removed.',
				highlighted: false,
			};
		}
		case 'eventEnd': {
			const endTime: number = numberIsValidAndFinite(changeSetItem.value)
				? changeSetItem.value
				: NaN;
			if (endTime > timeStamp) {
				return {
					subject: 'Scheduled End',
					description: numberIsValidAndFinite(changeSetItem.value)
						? `${new Date(changeSetItem.value).toLocaleString(
								LOCALE,
								ConfigREMEventForm.wholeDatestampFormatOptions,
						  )}`
						: 'Removed.',
					highlighted: false,
				};
			}
			break;
		}
		case 'eastboundLaneBlockageType':
			return {
				subject: 'Eastbound Lane Impact Type',
				description: (changeSetItem.value as string) ?? 'Removed.',
				highlighted: false,
			};
		case 'westboundLaneBlockageType':
			return {
				subject: 'Westbound Lane Impact Type',
				description: (changeSetItem.value as string) ?? 'Removed.',
				highlighted: false,
			};
		case 'northboundLaneBlockageType':
			return {
				subject: 'Northbound Lane Impact Type',
				description: (changeSetItem.value as string) ?? 'Removed.',
				highlighted: false,
			};
		case 'southboundLaneBlockageType':
			return {
				subject: 'Southbound Lane Impact Type',
				description: (changeSetItem.value as string) ?? 'Removed.',
				highlighted: false,
			};
		case 'eastboundLaneBlockage': {
			const laneBlockage = changeSetItem.value as LaneBlockage;
			const oldLaneBlockage = changeSetItem.previousValue as LaneBlockage;

			return {
				subject: 'Eastbound Lane Impacts',
				description: laneBlockageToTimelineEntry(laneBlockage, oldLaneBlockage),
				highlighted: false,
			};
		}
		case 'westboundLaneBlockage': {
			const laneBlockage = changeSetItem.value as LaneBlockage;
			const oldLaneBlockage = changeSetItem.previousValue as LaneBlockage;
			return {
				subject: 'Westbound Lane Impacts',
				description: laneBlockageToTimelineEntry(laneBlockage, oldLaneBlockage),
				highlighted: false,
			};
		}
		case 'northboundLaneBlockage': {
			const laneBlockage = changeSetItem.value as LaneBlockage;
			const oldLaneBlockage = changeSetItem.previousValue as LaneBlockage;
			return {
				subject: 'Northbound Lane Impacts',
				description: laneBlockageToTimelineEntry(laneBlockage, oldLaneBlockage),
				highlighted: false,
			};
		}
		case 'southboundLaneBlockage': {
			const laneBlockage = changeSetItem.value as LaneBlockage;
			const oldLaneBlockage = changeSetItem.previousValue as LaneBlockage;
			return {
				subject: 'Southbound Lane Impacts',
				description: laneBlockageToTimelineEntry(laneBlockage, oldLaneBlockage),
				highlighted: false,
			};
		}

		case 'locationDescription':
			return {
				subject: 'Location Notes',
				description: (changeSetItem.value as string) ?? `Removed`,
				highlighted: true,
			};

		case 'respondingUnitType': {
			return {
				subject: 'Responding Unit Type',
				description: (changeSetItem.value as string) ?? 'Removed',
				highlighted: false,
			};
		}

		case 'respondingUnitMember': {
			return {
				subject: 'Responding Unit Member',
				description: (changeSetItem.value as RespondingUnitMember)?.name?.toString() ?? 'Removed',
				highlighted: false,
			};
		}

		case 'dispositionType': {
			return {
				subject: 'Responding Unit Disposition',
				description: (changeSetItem.value as string) ?? 'Removed',
				highlighted: false,
			};
		}

		case 'assigned': {
			return {
				subject: 'Responding Unit Assigned',
				description: numberIsValidAndFinite(changeSetItem.value)
					? `${new Date(changeSetItem.value).toLocaleString(
							LOCALE,
							ConfigREMEventForm.wholeDatestampFormatOptions,
					  )}`
					: 'Removed.',
				highlighted: false,
			};
		}

		case 'arrived': {
			return {
				subject: 'Responding Unit Arrived',
				description: numberIsValidAndFinite(changeSetItem.value)
					? `${new Date(changeSetItem.value).toLocaleString(
							LOCALE,
							ConfigREMEventForm.wholeDatestampFormatOptions,
					  )}`
					: 'Removed.',
				highlighted: false,
			};
		}

		case 'completed': {
			return {
				subject: 'Responding Unit Completed',
				description: numberIsValidAndFinite(changeSetItem.value)
					? `${new Date(changeSetItem.value).toLocaleString(
							LOCALE,
							ConfigREMEventForm.wholeDatestampFormatOptions,
					  )}`
					: 'Removed.',
				highlighted: false,
			};
		}

		case 'linkedEventA': {
			return {
				subject: 'Linking',
				description: numberIsValidAndFinite(changeSetItem.value)
					? `Created link to event ID ${changeSetItem.value}`
					: `Link to event ID ${changeSetItem.value as number} Removed.`,
				highlighted: false,
			};
		}

		case 'linkedEventB': {
			return {
				subject: 'Linking',
				description: numberIsValidAndFinite(changeSetItem.value)
					? `Created link to event ID ${changeSetItem.value}`
					: `Link to event ID ${changeSetItem.value as number} Removed.`,
				highlighted: false,
			};
		}

		case 'value':
			return {
				subject: 'Event Attribute',
				description:
					(changeSetItem.value as string) ?? `"${changeSetItem.previousValue as string}" Removed.`,
				highlighted: false,
			};

		case 'text': {
			return isValidString(changeSetItem.value)
				? {
						subject: 'Email Notification Message',
						description: `"${changeSetItem.value}"`,
						highlighted: false,
				  }
				: undefined;
		}

		case 'notes': {
			return isValidString(changeSetItem.value)
				? {
						subject: 'Operator note appended',
						description: `"${changeSetItem.value}"`,
						highlighted: true,
				  }
				: undefined;
		}
		case 'internalContactDetails': {
			return isValidString(changeSetItem.value)
				? {
						subject: 'Internal contact details',
						description: `"${changeSetItem.value}"`,
						highlighted: true,
				  }
				: undefined;
		}
		case 'additionalPublicText': {
			return isValidString(changeSetItem.value)
				? {
						subject: 'Additional public text',
						description: `"${changeSetItem.value}"`,
						highlighted: true,
				  }
				: undefined;
		}
		case 'additionalPublicAudio': {
			return isValidString(changeSetItem.value)
				? {
						subject: 'Additional public audio',
						description: `"${changeSetItem.value}"`,
						highlighted: true,
				  }
				: undefined;
		}
		case 'emailAddress':
			return {
				subject: 'Individual Notification Recipient Modified',
				description: `Added "${changeSetItem.value as string}"`,
				highlighted: false,
			};

		case 'Added':
			return {
				subject: 'Notification Recipient Added',
				description: `${changeSetItem.value as string}`,
				highlighted: false,
			};
		case 'Removed':
			return {
				subject: 'Notification Recipient Removed',
				description: `${changeSetItem.value as string}`,
				highlighted: false,
			};

		case 'name':
			return {
				subject: 'Group Notification Recipient Modified',
				description: `Added "${changeSetItem.value as string}"`,
				highlighted: false,
			};
		case 'eventStatus':
			if (changeSetItem.value === EventStatus.COMPLETED) {
				return {
					subject: 'Event Closed',
					description: `${new Date(timeStamp).toLocaleString(
						LOCALE,
						ConfigREMEventForm.wholeDatestampFormatOptions,
					)}`,
					highlighted: false,
				};
			}
			if (
				changeSetItem.value === EventStatus.NORMAL &&
				changeSetItem.previousValue === EventStatus.COMPLETED
			) {
				return {
					subject: 'Event Reopened',
					description: `${new Date(timeStamp).toLocaleString(
						LOCALE,
						ConfigREMEventForm.wholeDatestampFormatOptions,
					)}`,
					highlighted: false,
				};
			}
			break;
		case 'includeInEventFeed':
			if (!CARS_EVENT_INTEGRATION_ENABLED) {
				break;
			}
			return {
				subject: 'Display on 511',
				description: `${changeSetItem.value ? 'Event sent to 511.' : 'Event excluded from 511.'}`,
				highlighted: false,
			};
		case 'city':
			return {
				subject: 'City',
				description:
					changeSetItem.value && Array.isArray(changeSetItem.value)
						? (changeSetItem.value as string[])[0]
						: 'City removed',
				highlighted: false,
			};
		case 'crossStreetStart':
			return {
				subject: 'Start Cross Street',
				description: (changeSetItem.value as string) ?? 'Street unknown/removed',
				highlighted: false,
			};
		case 'crossStreetEnd':
			return {
				subject: 'End Cross Street',
				description: (changeSetItem.value as string) ?? 'Street unknown/removed',
				highlighted: false,
			};
		case 'proximity':
			return {
				subject: 'Proximity',
				description: (changeSetItem.value as string) ?? 'N/A',
				highlighted: false,
			};
		//	record status changes record additions / deletions for collections of data entries
		case 'recordStatus':
			switch (changeSetName) {
				case 'vehicles':
					if (changeSetItem.value === RecordStatus.DELETED) {
						return {
							subject: 'Vehicles',
							description: 'Entry deleted.',
							highlighted: false,
						};
					}
					//	ignore vehicle record statuses besides deleted entries
					return undefined;
				case 'event':
					//	ignore event record creation / deletion
					break;

				case 'respondingUnitDispositions':
					if (changeSetItem.value === RecordStatus.DELETED) {
						return {
							subject: 'Responding Unit Disposition',
							description: 'Entry deleted.',
							highlighted: false,
						};
					}
					//	ignore respondingUnitDisposition record statuses besides deleted entries
					return undefined;

				case 'respondingUnits':
					if (changeSetItem.fieldName === 'recordStatus') {
						return {
							subject: 'Responding Unit Status',
							description: `${changeSetItem.value as string}`,
							highlighted: false,
						};
					}
					//	ignore respondingUnit record statuses besides deleted entries
					return undefined;

				case 'attributes':
					if (changeSetItem.value === null) {
						return {
							subject: 'Event Attribute',
							description: `Attribute deleted: ${changeSetItem.previousValue as string}`,
							highlighted: false,
						};
					}
					//	ignore attribute record statuses besides deleted entries
					return undefined;

				case 'eventLinkages':
					if (changeSetItem.value === RecordStatus.DELETED) {
						return {
							subject: 'Linking',
							description: 'Link to event removed',
							highlighted: false,
						};
					}

					break;

				case 'timelineAnnotations':
					//	no timeline entry for timeline annotation status changing
					break;

				//	TODO: include *which* individual or group was removed, pending API update to support

				case 'emailRecipients':
					if (changeSetItem.value === RecordStatus.DELETED) {
						return {
							subject: 'Notification Recipients Modified',
							description: 'Deleted individual recipipent.',
							highlighted: false,
						};
					}
					break;

				case 'emailGroups':
					if (changeSetItem.value === RecordStatus.DELETED) {
						return {
							subject: 'Group Notification Recipient Modified',
							description: 'Deleted recipient group.',
							highlighted: false,
						};
					}
					break;
				default:
					if (DebuggingConfig.showConsoleLogs) {
						console.warn(
							`unexpected '${changeSetName}' changeset name "${changeSetItem.fieldName}" in`,
							changeSetItem,
						);
					}
					break;
			}
			break;
		//	this meta information doesn't need to be shown on the timeline
		case 'region':
		case 'negativeLaneDir':
		case 'negativeLaneCount':
		case 'negativeLaneBlockage':
		case 'negativeLaneBlockageType':
		case 'positiveLaneDir':
		case 'positiveLaneCount':
		case 'positiveLaneBlockage':
		case 'positiveLaneBlockageType':
		case 'eventLocationType':
		case 'createdBy':
		case 'verifiedBy':
		case 'created':
			//	ignore this entry
			break;
		default:
			if (DebuggingConfig.showConsoleLogs) {
				console.warn(`unexpected '${changeSetName}' field name:`, changeSetItem);
			}
	}
	return undefined;
};

export const TimelineSortOrder = [
	'Author',
	'Event Started',
	'Event Closed',
	'Headline',
	'Event Source',
	'Source Name',
	'Source Phone Number',
	'Route',
	'Start Cross Street',
	'End Cross Street',
	'Proximity',
	'City',
	'Mile Marker Start',
	'Mile Marker End',
	'County',
	'Counties',
	'District',
	'Districts',
	'Sub-District',
	'Sub-Districts',
	'Unit',
	'Units',
	'Westbound Lane Impact',
	'Westbound Lane Impacts',
	'Westbound Lane Impact Type',
	'Eastbound Lane Impact',
	'Eastbound Lane Impacts',
	'Eastbound Lane Impact Type',
	'Northbound Lane Impact',
	'Northbound Lane Impacts',
	'Northbound Lane Impact Type',
	'Southbound Lane Impact',
	'Southbound Lane Impacts',
	'Southbound Lane Impact Type',
	'Location Notes',
	'Operator note appended',
	'Internal contact details',
	'Additional public text',
	'Additional public audio',
	'Visual Verification',
	'Scheduled Start',
	'Scheduled End',
	'DMS Added',
	'DMS Removed',
	'Vehicles',
	'Vehicle License Plate',
	'Vehicle Description',
	'Responding Unit Type',
	'Responding Unit Status',
	'Responding Unit Disposition',
	'Responding Unit Member',
	'Responding Unit Assigned',
	'Responding Unit Arrived',
	'Responding Unit Completed',
	'Event Attribute',
	'Priority',
	'Linking',
	'Notification Recipient Added',
	'Notification Recipient Removed',
	'Email Notification Message',
	'Display on 511',
];
