import { css } from 'lit';
import { CardinalDir, DisplayedMessageDto, FrameDto } from '../typings/api';
import { DMSSignType } from '../typings/api';

export const TopbarHeight = 86; // px
export const MobileMenuHeight = 60; // px
export const MobileBreakpoint = 1000; //	px
export const MiddleBreakpoint = 1130; //	px
export const MAP_POPUP_WIDTH_DESKTOP = 500; // px
export const MAP_POPUP_WIDTH_MOBILE = 300; // px

export const LOCALE = 'en-US';

export enum Breakpoint {
	desktop = 'desktop',
	mobile = 'mobile',
}

export enum View {
	map = 'map',
	table = 'table',
}

export const EventPriorityMin = 1;

export const EventPriorityMax = 10;

export const MILLISECONDS_PER_SECOND = 1000;
export const MILLISECONDS_PER_MINUTE = 60 * MILLISECONDS_PER_SECOND;
export const MILLISECONDS_PER_HOUR = 60 * MILLISECONDS_PER_MINUTE;
export const MILLISECONDS_PER_DAY = 24 * MILLISECONDS_PER_HOUR;
export const MILLISECONDS_PER_WEEK = 7 * MILLISECONDS_PER_DAY;
export const METERS_PER_MILE = 1609.344;

export const DIFFERENT_COLORS = [
	'#03e3fc',
	'#803E75',
	'#fc0362',
	'#A6BDD7',
	'#C10020',
	'#fc03d3',
	'#817066',
	'#007D34',
	'#F6768E',
	'#00538A',
	'#FF7A5C',
	'#53377A',
	'#3003fc',
	'#B32851',
	'#03fc52',
	'#7F180D',
	'#93AA00',
	'#593315',
	'#F13A13',
	'#232C16',
];

export enum RelativeDateTime {
	IMMEDIATELY = 'IMMEDIATELY',
	TOMORROW = 'TOMORROW',
	WEEK = 'WEEK',
	MONTH = 'MONTH',
	CUSTOM = 'CUSTOM',
}

export enum RelativeDuration {
	INDEFINITELY = 'INDEFINITELY',
	HOUR = 'HOUR',
	DAY = 'DAY',
	WEEK = 'WEEK',
	CUSTOM = 'CUSTOM',
}

export const FullCardinalByKey = {
	// rem-event-affected-lanes-interface
	[CardinalDir.N]: 'NB',
	[CardinalDir.W]: 'WB',
	[CardinalDir.S]: 'SB',
	[CardinalDir.E]: 'EB',
};

export const CardinalNameByKey = {
	[CardinalDir.N]: 'North',
	[CardinalDir.W]: 'West',
	[CardinalDir.S]: 'South',
	[CardinalDir.E]: 'East',
};

export const FullCardinalNameByKey = {
	[CardinalDir.N]: 'Northbound',
	[CardinalDir.E]: 'Eastbound',
	[CardinalDir.S]: 'Southbound',
	[CardinalDir.W]: 'Westbound',
};

export const ReverseCardinalNameByKey = {
	[CardinalDir.N]: 'South',
	[CardinalDir.W]: 'East',
	[CardinalDir.S]: 'North',
	[CardinalDir.E]: 'West',
};

export enum Direction {
	positive = 'positive',
	negative = 'negative',
}

export enum Shoulder {
	outside = 'outside',
	inside = 'inside',
}

export enum CCTVView {
	map = 'map',
	table = 'table',
	editdevice = 'editdevice',
}

export enum DMSView {
	map = 'map',
	table = 'table',
	group = 'group',
	sign = 'sign',
	'sign-queue' = 'sign-queue',
	'image-library' = 'image-library',
	'custom-messages' = 'custom-messages',
	'custom-message' = 'custom-message',
}

export enum REMView {
	map = 'map',
	table = 'table',
	event = 'event',
}

export enum RampMeterView {
	map = 'map',
	table = 'table',
}

export enum HHView {
	table = 'table',
	helper = 'helper',
	sspAdmin = 'ssp-admin',
}

export enum MetricsView {
	events = 'events',
	event = 'event',
	helpers = 'helpers',
	helper = 'helper',
	dashboard = 'dashboard',
}

export enum AGGridSort {
	asc = 'asc',
	desc = 'desc',
}

export enum SignsLoaderState {
	fetch = 'fetch',
	groupFetch = 'groupFetch',
	customFetch = 'customFetch',
	groupSave = 'groupSave',
	groupDelete = 'groupDelete',
	queue = 'queue',
}

export enum DmsSignTypes {
	TTS = 'Travel Time',
	DMS = 'DMS Line Matrix',
	TPIMS = 'TPIMS',
	VSL = 'Variable Speed Limit',
	PDMS = 'Portable Message Sign',
}

export enum DmsSignAddlTypes {
	DMSF = 'DMS Full Color Matrix',
}
export const DmsSignFilterTypes = {
	...DmsSignTypes,
	...DmsSignAddlTypes,
};
export type DmsSignFilterTypes = typeof DmsSignFilterTypes;

export enum AppSection {
	LOGIN = 'login',
	CCTV = 'cctv',
	DMS = 'dms',
	REM = 'rem',
	RAMP_METER = 'ramp-meter',
	HH = 'highway-helper',
	METRICS = 'metrics',
	USER_GROUPS = 'user-groups',
}

export enum ModalSection {
	GROUP_MESSAGE = 'group-message',
	GROUP = 'group',
	IMAGE = 'image',
	PASSWORD_RESET = 'password-reset',
}

export enum FrameTypes {
	LONG = 'Long message',
	SHORT = 'Short message',
	GRAPHIC = 'Graphic message',
}

export const MaxCharactersByFrameType: Record<FrameTypes, number> = {
	[FrameTypes.LONG]: 20,
	[FrameTypes.SHORT]: 8,
	[FrameTypes.GRAPHIC]: 0,
};

export enum CCTVCameraTypes {
	FILTER_CCTV = 'CCTV',
	FILTER_DMSCAM = 'DMS Cam',
	FILTER_HHDASH = 'HH Dash',
	FILTER_HHPTZ = 'HH PTZ',
	FILTER_IRD = 'IRD',
	FILTER_PORTABLE = 'Portable',
	FILTER_RWIS = 'RWIS',
	FILTER_SIGNAL = 'Signal',
	FILTER_TRUCKPARKING = 'Truck Parking',
	FILTER_WORKZONE = 'Workzone',
}

/* eslint-disable camelcase */
export enum CCTVDeviceTypes {
	NONE = 'None',
	cctv = 'cctv',
	cctv_rwis = 'cctv_rwis',
	cctv_city = 'cctv_city',
	cctv_irv = 'cctv_irv',
	cctv_irv_drn = 'cctv_irv_drn',
	cctv_irv_usb = 'cctv_irv_usb',
	cctv_port = 'cctv_port',
	cctv_sig = 'cctv_sig',
	cctv_tpa = 'cctv_tpa',
	cctv_tps = 'cctv_tps',
	cctv_dms = 'cctv_dms',
	cctv_wz = 'cctv_wz',
}

export enum CCTVDeviceTypeFilters {
	NONE = 'None',
}

export enum CustomMessageTitles {
	CUSTOM = 'Custom Message',
	LONG = 'Long Message',
	SHORT = 'Short Message',
	GRAPHIC = 'Graphic Message',
	COMBO = 'Combo Message',
}

export enum Dimensions {
	TOPBAR_HEIGHT = 70,
	HEADER_HEIGHT = 86,
}

export const REM_EVENT_COLUMNS = <const>[
	'respondingUnits',
	'vehicle',
	'timeAssigned',
	'timeArrived',
	'timeCompleted',
	'type',
	'id',
	'route',
	'milemarker',
	'direction',
	'county',
	'district',
	'dateCreated',
	'attributes',
	'lastUpdated',
	'schedule',
	'lastUser',
	'lastUpdated',
	'display511',
	'signMessages',
];

export const DMS_COLUMNS = <const>[
	'number',
	'name',
	'status',
	'queue',
	'route',
	'mileMarker',
	'direction',
	'group',
	'type',
];

export const DMS_GROUP_COLUMNS = <const>['name', 'signs'];

export const CUSTOM_MESSAGE_COLUMNS = <const>[
	'id',
	'frame1',
	'type',
	'created',
	'lastUsed',
	'createdBy',
	'permanent',
];

export const DMS_IMAGE_COLUMNS = <const>['preview', 'title', 'uploaded', 'created'];

export const HH_COLUMNS = <const>[
	'name',
	'status',
	'disposition',
	'commission',
	'zone',
	'GPS',
	'REM',
	'dashcam',
	'ptzcam',
];

export const CUSTOM_MESSAGE_SIGN_TYPES: DMSSignType[] = [DMSSignType.DMS, DMSSignType.PDMS];

export const IPV4_REGEX =
	/^(?:(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:(6553[0-5]|655[0-2][0-9]|65[0-4][0-9][0-9]|6[0-4][0-9][0-9][0-9]|[1-5](\d){4}|[1-9](\d){0,3}))?$/;

export const urlRegex =
	/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

/**
 * @see https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
 */
export const EMAIL_ADDRESS_REGEX =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum MessageStates {
	UNSENT = 'UNSENT',
	SENT = 'SENT',
	ERROR = 'ERROR',
}

export const STATIC_TEXT_TOOLTIPS = {
	dmsPreviews: {
		unsavedEvent:
			"These DMS messages will be saved when the event is created. The queue number indicates the position that the message will be in when the event is created. Click on the preview box to view the sign's full message queue.",
		savedEvent:
			"The queue number indicates the position of the message in the sign's queue. Click on the preview box to view the sign's full message queue. ",
	},
	signsTable: {
		status:
			'<span style="color: rgb(var(--brand-status-good));">Active</span> - sign is online and currently displaying a message<br>' +
			'<span style="color: rgb(var(--brand-status-caution));">Inactive</span> - sign is online but not currently displaying a message<br>' +
			'<span style="color: rgb(var(--brand-status-danger));">Offline</span> - sign will not receive updates from CARS-x',
	},
};

export enum SharedEventNames {
	METRICS_HELPERS_CSV_CLICKED = 'metrics:helpersCsvClicked',
	METRICS_EVENTS_CSV_CLICKED = 'metrics:eventsCsvClicked',
}

export const BLANK_FRAME: FrameDto = {
	text: '[jl3]Blank',
};

export const BLANK_DISPLAYED_MESSAGE: DisplayedMessageDto = {
	frame1: BLANK_FRAME,
	frame2: undefined,
	frame3: undefined,
	messageType: undefined,
};

export enum MessageTypes {
	EVENT = 'EVENT',
	CUSTOM = 'CUSTOM_MESSAGE',
	TTS = 'TRAVEL_TIME',
	TPIMS = 'TPIMS',
	CONGESTION = 'CONGESTION',
}

export enum HttpStatusCode {
	CONFLICT = 409,
	UNAUTHORIZED = 401,
	FORBIDDEN = 403,
	I_AM_A_TEAPOT = 418,
}

export enum BadgeType {
	GOOD = 'good',
	CAUTION = 'caution',
	DANGER = 'danger',
}

export enum Region {
	CENTRAL = 'Central Indiana',
	NORTHWEST = 'Northwest Indiana',
	SOUTHERN = 'Southern Indiana',
	SOUTHWEST = 'Southwest Indiana',
}

export enum FrameTextJustifications {
	LEFT = 'left',
	CENTER = 'center',
	RIGHT = 'right',
}

export const FrameTextJustificationIcons: Record<FrameTextJustifications, string> = {
	[FrameTextJustifications.LEFT]: 'img/sprites.svg#left-justification',
	[FrameTextJustifications.CENTER]: 'img/sprites.svg#middle-justification',
	[FrameTextJustifications.RIGHT]: 'img/sprites.svg#right-justification',
};

export enum QueueState {
	UNSENT = 'UNSENT',
	SENT = 'SENT',
	SENDING = 'SENDING',
	ERROR = 'ERROR',
}
