import { css } from 'lit';
/* eslint-disable max-lines */
import { ComboBoxOption } from 'cra-web-components/components/combo-box/combo-box';
import differenceWith from 'lodash-es/differenceWith';
import { createSelector } from 'reselect';
import { DebuggingConfig } from '@config/ConfigCARSx';
import { EventType, EventTypeConfig } from '@/config/ConfigREMEventTypes';
import {
	AuthorityDto,
	CardinalDir,
	ChangeSetItem,
	CurrentEditorsDto,
	DraftEvent,
	EventLinkageDto,
	EventStatus,
	LaneImpactValue,
	LocationTypes,
	NamedPointDto,
	PhraseDto,
	Polarity,
	PreviewDtoResponse,
	QuantityDto,
	QuantityTypeDto,
	RampDto,
	RoadEventDto,
	RoadEventTimelineDto,
	RouteDto,
	SignDto,
	SignSelection,
	TimelineEntry,
	UserPermissions,
	UserRoles,
} from '../../../typings/api';
import {
	MinMaxTuple,
	StartEnd,
	TimelineDay,
	TimelineDayEntryChange,
} from '../../../typings/shared-types';
import { EventAttribute } from '../../components/rem/rem-attributes';
import {
	ConfigREMEventForm,
	REMExternalFilterOptions,
	extraneousRouteOptions,
	REMLiteTableFilters,
} from '../../config/ConfigREM';
import {
	TimelineSortOrder,
	UNKNOWN_USER,
	resolveChangeSetItemToTimelineDayEntryChange,
} from '../../config/ConfigREMTimeline';
import { AppSection, Direction, FullCardinalByKey, LOCALE } from '../../constants';
import formatTimezoneSup from '../../utils/format-timezone-sup';
import { isREMDraftEvent } from '../../utils/type-guards';
import {
	isLaneImpacted,
	isValidNumber,
	isValidString,
	numberIsValidAndFinite,
} from '../../utils/utils';
import { signsSelector } from '../dms/dms-selectors';
import { RootState } from '../redux-store';
import { userHasPermission, userHasRole, userIsHoosierHelper } from '../user-permissions';

export const selectDraftEventByModule = createSelector(
	[
		(state: RootState): AppSection | undefined => state.routing.page ?? undefined,
		(state: RootState): Partial<RoadEventDto> | undefined => state.rem.draftEvent,
		(state: RootState): RoadEventDto | undefined => state.metrics.event,
	],
	(
		page: AppSection | undefined,
		remEvent: Partial<RoadEventDto> | undefined,
		metricsEvent: RoadEventDto | undefined,
	): Partial<RoadEventDto> | undefined => {
		if (page === AppSection.REM) {
			return remEvent;
		}
		if (page === AppSection.METRICS) {
			return metricsEvent;
		}

		return undefined;
	},
);

export const selectEventTimelineByModule = createSelector(
	[
		(state: RootState): AppSection | undefined => state.routing.page ?? undefined,
		(state: RootState): RoadEventTimelineDto | undefined => state.rem.eventTimeline,
		(state: RootState): RoadEventTimelineDto | undefined => state.metrics.eventTimeline,
	],
	(
		page: AppSection | undefined,
		remTimeline: RoadEventTimelineDto | undefined,
		metricsTimeline: RoadEventTimelineDto | undefined,
	): RoadEventTimelineDto | undefined => {
		if (page === AppSection.REM) {
			return remTimeline;
		}
		if (page === AppSection.METRICS) {
			return metricsTimeline;
		}

		return undefined;
	},
);

export const selectLaneCount = (state: RootState, direction: Direction): number | undefined => {
	return selectDraftEventByModule(state)?.locationDetails?.lanes?.[`${direction}`]?.laneCount;
};

export const selectCardinalDir = (state: RootState, direction: Direction): CardinalDir => {
	return selectDraftEventByModule(state)?.locationDetails?.lanes?.[`${direction}`]
		?.cardinalDir as CardinalDir;
};

export const selectRouteIdentifiersToComboBoxOptions = createSelector(
	[
		(state: RootState): RouteDto[] | undefined => state.rem?.filteredRoutes,
		(state: RootState): RouteDto[] | undefined => state.rem.eventFields?.routes,
	],
	(filteredRoutes?: RouteDto[], eventRoutes?: RouteDto[]): ComboBoxOption[] | undefined => {
		const filteredRouteIdentifiers =
			filteredRoutes?.map((filteredRoute) => filteredRoute.routeIdentifier) ?? undefined;

		const filteredRouteOptions: ComboBoxOption[] = [...extraneousRouteOptions];

		eventRoutes?.forEach((route) => {
			if (
				route.routeIdentifier !== undefined &&
				(filteredRouteIdentifiers === undefined ||
					filteredRouteIdentifiers.includes(route.routeIdentifier))
			) {
				filteredRouteOptions.push({
					value: route.routeIdentifier,
				});
			}
		});

		return filteredRouteOptions;
	},
);

export const selectEventTypesToComboBoxOptions = (state: RootState): ComboBoxOption[] =>
	state.rem.eventFields?.eventTypes?.map(
		(eventType) =>
			({
				// ID number is not needed for this combobox, as the label string is what is passed to the backend
				value: eventType.name,
				label: eventType.name,
				color: eventType.name ? EventTypeConfig[eventType.name as EventType]?.color : null,
			} as ComboBoxOption),
	) ?? [];

export const selectEventTypesAndIdsToComboBoxOptions = (state: RootState): ComboBoxOption[] =>
	state.rem.eventFields?.eventTypes?.map(
		(eventType) =>
			({
				value: eventType.id?.toString() ?? eventType.name,
				label: eventType.name,
			} as ComboBoxOption),
	) ?? [];

function selectEventIDsToComboBoxOptionsComparator(a: RoadEventDto, b: EventLinkageDto): boolean {
	if (a.id === b.linkedEventId) {
		return true;
	}
	return false;
}
export const selectEventIDsToComboBoxOptions = (
	state: RootState,
	exclude?: EventLinkageDto[],
): ComboBoxOption[] | undefined => {
	const directionsForEvent = (event: RoadEventDto): string[] => {
		const directions: string[] = [];

		if (
			isLaneImpacted(event.negativeLaneBlockage) &&
			event.locationDetails.negativeLanes !== undefined
		) {
			const negativeDirection = event.locationDetails.lanes?.negative.cardinalDir
				? FullCardinalByKey[event.locationDetails.lanes.negative.cardinalDir]
				: undefined;
			if (negativeDirection !== undefined) {
				directions.push(negativeDirection);
			}
		}

		if (
			isLaneImpacted(event.positiveLaneBlockage) &&
			event.locationDetails.positiveLanes !== undefined
		) {
			const positiveDirection = event.locationDetails.lanes?.positive.cardinalDir
				? FullCardinalByKey[event.locationDetails.lanes.positive.cardinalDir]
				: undefined;
			if (positiveDirection !== undefined) {
				directions.push(positiveDirection);
			}
		}

		return directions;
	};

	const eventToComboboxOption = (event: RoadEventDto): ComboBoxOption => {
		return {
			value: event.id.toString(),
			label: `${event.eventType} on ${event.route} ${directionsForEvent(event).join('/')} ${
				event.startMileMarker ? `MM ${event.startMileMarker}` : ''
			} ${event.endMileMarker ? `- ${event.endMileMarker}` : ''} [${event.id.toString()}]`,
		};
	};

	if (exclude) {
		const filteredArray = differenceWith(
			state.rem.events ?? [],
			exclude,
			selectEventIDsToComboBoxOptionsComparator,
		);
		return filteredArray.map((event) => eventToComboboxOption(event));
	}
	return state.rem.events?.map((event) => eventToComboboxOption(event));
};

export const selectRespondingUnitTypesToComboBoxOptions = (
	state: RootState,
): ComboBoxOption[] | undefined =>
	state.rem?.eventFields?.respondingUnitTypes?.map(
		(item) =>
			({
				value: item.name,
				label: item.name,
				recommended: false,
			} as ComboBoxOption),
	);

export const selectRespondingUnitDispositionsToComboBoxOptions = (
	state: RootState,
): ComboBoxOption[] | undefined =>
	state.rem.eventFields?.respondingUnitDispositionTypes?.map(
		(disposition) =>
			({
				value: disposition.name,
				label: `${disposition?.name ?? '(no name)'} - ${
					disposition?.description ?? '(no description)'
				}`,
				recommended: false,
			} as ComboBoxOption),
	);

export const selectRespondingUnitMembersToComboBoxOptions = (
	state: RootState,
): ComboBoxOption[] | undefined =>
	state.rem.eventFields?.respondingUnitTypes
		?.find((type) => type.name === 'HOOSIER HELPER')
		?.members?.map(
			(unit) =>
				({
					value: unit.name,
					label: unit.name,
					recommended: false,
				} as ComboBoxOption),
		);

export const selectEventSourcesToComboBoxOptions = (
	state: RootState,
): ComboBoxOption[] | undefined =>
	state.rem.eventFields?.eventSources?.map((source) => ({
		value: source,
		label: source,
		recommended: false,
	}));

export const selectRouteMileRanges = (state: RootState): MinMaxTuple[] | undefined =>
	state.rem.eventFields?.routes
		?.find((routeDTO) => routeDTO.routeIdentifier === selectDraftEventByModule(state)?.route)
		?.mileRanges?.map((mileRange) => [mileRange[0], mileRange[1]]);

export const selectEventTimelineByDay = (state: RootState): TimelineDay[] | undefined =>
	(selectEventTimelineByModule(state)?.timeline?.entries as TimelineEntry[])?.reduce(
		(timelineDays: TimelineDay[], timelineEntry: TimelineEntry): TimelineDay[] => {
			//	is there already a container for the day this change occurred on?
			const dateString = new Date(timelineEntry.timestamp).toLocaleDateString(
				LOCALE,
				ConfigREMEventForm.timelineDateFormatOptions,
			);
			const lastTimelineDay = timelineDays[timelineDays.length - 1];
			let timelineDay: TimelineDay;
			if (lastTimelineDay?.dateString === dateString) {
				timelineDay = lastTimelineDay;
			} else {
				timelineDay = {
					dateString,
					entries: [],
				};
				timelineDays.push(timelineDay);
			}
			//	what changes occured on this day?
			const timeString = formatTimezoneSup(
				new Date(timelineEntry.timestamp).toLocaleTimeString(
					LOCALE,
					ConfigREMEventForm.timelineTimeFormatOptions,
				),
			);
			const user = timelineEntry.changeSets?.[0]?.changeSetItems?.[0]?.createdBy ?? UNKNOWN_USER;
			const changes: TimelineDayEntryChange[] = [];

			timelineEntry.changeSets?.forEach((changeSet) => {
				changeSet.changeSetItems?.forEach((changeSetItem) => {
					if (changeSet.name !== undefined) {
						const timelineDayEntryChange = resolveChangeSetItemToTimelineDayEntryChange(
							changeSet.name,
							changeSetItem as ChangeSetItem,
							timelineEntry.timestamp,
						);
						if (timelineDayEntryChange === undefined) {
							/*	//	uncomment to track down missing timeline entries
							if (DebuggingConfig.showConsoleLogs) {
								console.warn(
									'no timelineDayEntryChange could be resolved to for',
									changeSet.name,
									changeSetItem,
								);
							}
							*/
							return;
						}
						if (changeSetItem.fieldName?.includes('linkedEvent')) {
							if ((changeSetItem as ChangeSetItem).value !== state.rem.eventTimeline?.id) {
								changes.push(timelineDayEntryChange);
							}
						} else if (timelineDayEntryChange !== undefined) {
							changes.push(timelineDayEntryChange);
						}
					}
				});
			});

			if (changes.length === 0) {
				if (DebuggingConfig.showConsoleLogs) {
					console.warn(
						`no ChangeSetItems could be resolved to TimelineDayEntryChanges for "${dateString} ${timeString}"`,
					);
				}
			}

			const unSortedEntrySubjects = new Set();

			changes.sort((a, b) => {
				const aIndex = TimelineSortOrder.indexOf(a.subject);
				const bIndex = TimelineSortOrder.indexOf(b.subject);
				if (aIndex === -1) {
					unSortedEntrySubjects.add(a.subject);
					return 1;
				}
				if (bIndex === -1) {
					unSortedEntrySubjects.add(b.subject);
					return -1;
				}
				const order = aIndex - bIndex;
				return order;
			});

			if (unSortedEntrySubjects.size > 0) {
				if (DebuggingConfig.showConsoleLogs) {
					console.warn(
						`no custom ordering found for the following ${unSortedEntrySubjects.size} entries:`,
						unSortedEntrySubjects,
					);
				}
			}

			timelineDay.entries.push({
				timeString,
				user,
				changes,
			});
			return timelineDays;
		},
		[] as TimelineDay[],
	);

export const selectHasSufficientDataForDMSPreview = (state: RootState): boolean =>
	//	required: event type, route, mile marker,
	(selectDraftEventByModule(state)?.eventType !== undefined &&
		selectDraftEventByModule(state)?.route !== undefined &&
		isValidNumber(selectDraftEventByModule(state)?.startMileMarker) &&
		(state.rem.mileMarkersValidForRoute || state.routing.page === AppSection.METRICS) &&
		//	and at least one lane slowed / closed
		//	positive lane impact
		((selectDraftEventByModule(state)?.positiveLaneBlockageType !== undefined &&
			selectDraftEventByModule(state)?.positiveLaneBlockageType !== LaneImpactValue.NA &&
			isLaneImpacted(selectDraftEventByModule(state)?.positiveLaneBlockage)) ||
			//	negative lane impact
			(selectDraftEventByModule(state)?.negativeLaneBlockageType !== undefined &&
				selectDraftEventByModule(state)?.negativeLaneBlockageType !== LaneImpactValue.NA &&
				isLaneImpacted(selectDraftEventByModule(state)?.negativeLaneBlockage)))) ??
	false;

export const selectShowSigns = (state: RootState): boolean => {
	const event = selectDraftEventByModule(state);
	const eventTypeShowsByDefault =
		event?.eventType !== undefined && EventTypeConfig[event?.eventType]?.defaultFormSections?.DMS;

	const eventDMSPreviewsPreviouslyErrored = state.rem.eventDMSPreviewMessages === null;

	const eventDMSPreviewsSomePresent =
		state.rem.eventDMSPreviewMessages !== undefined &&
		Object.keys(state.rem.eventDMSPreviewMessages)?.length > 0;

	const eventDMSManuallyEnabled = state.rem.formSections.DMS;

	const showSigns =
		eventDMSManuallyEnabled ||
		eventTypeShowsByDefault ||
		eventDMSPreviewsSomePresent ||
		eventDMSPreviewsPreviouslyErrored;

	return showSigns;
};

export const selectShowVehicles = (state: RootState): boolean => {
	const event = selectDraftEventByModule(state);

	return (
		((event?.eventType !== undefined &&
			EventTypeConfig[event?.eventType]?.defaultFormSections?.VEHICLES) ||
			(event?.vehicles !== undefined && event?.vehicles?.length > 0) ||
			state.rem.formSections.VEHICLES) ??
		false
	);
};

export const selectShowRespondingUnits = (state: RootState): boolean => {
	const event = selectDraftEventByModule(state);

	return (
		((event?.eventType !== undefined &&
			EventTypeConfig[event?.eventType]?.defaultFormSections?.RESPONDING_UNITS) ||
			(event?.respondingUnits !== undefined && event?.respondingUnits?.length > 0) ||
			state.rem.formSections.RESPONDING_UNITS) ??
		false
	);
};

export const selectCurrentDetailsChanged = (state: RootState): boolean => {
	if (!state.rem.draftEvent) return false;
	if (state.rem?.loading) return false;

	if (typeof state.rem.draftEvent?.currentDetailsId !== 'number') return false;

	if (
		typeof state.rem.draftEvent?.currentDetailsId !== 'number' ||
		typeof state.rem?.currentDetailsId !== 'number'
	) {
		return false;
	}
	if (state.rem.draftEvent?.currentDetailsId !== state.rem?.currentDetailsId) {
		return true;
	}
	return false;
};

export const selectIsVerified = (state: RootState): boolean =>
	numberIsValidAndFinite(selectDraftEventByModule(state)?.verified);

export const selectAllowCreate = (state: RootState): boolean =>
	//	if it doesn't have an id yet, it hasn't been submitted to the server yet
	isREMDraftEvent(state.rem.draftEvent) &&
	state.rem.draftEvent.id === undefined &&
	(state.rem.mileMarkersValidForRoute ?? true);

export const selectAllowUpdate = (state: RootState): boolean => {
	const isOutOfDateWithServer = selectCurrentDetailsChanged(state);
	//	if the draft event has changes from the serverside version of the event
	return (
		isREMDraftEvent(state.rem.draftEvent) &&
		state.rem.draftEvent.id !== undefined &&
		state.rem.unsavedDraftEvent &&
		(state.rem.mileMarkersValidForRoute ?? true) &&
		!isOutOfDateWithServer
	);
};

export const selectAllowComplete = (state: RootState): boolean => {
	const isOutOfDateWithServer = selectCurrentDetailsChanged(state);
	//	if it has an id, then it can be completed
	return (
		!userIsHoosierHelper() &&
		isREMDraftEvent(state.rem.draftEvent) &&
		state.rem.draftEvent.id !== undefined &&
		(state.rem.mileMarkersValidForRoute ?? true) &&
		!isOutOfDateWithServer
	);
};

export const selectAllowCreateAndComplete = (state: RootState): boolean => {
	return (
		!userIsHoosierHelper() &&
		isREMDraftEvent(state.rem.draftEvent) &&
		state.rem.draftEvent.id === undefined &&
		(state.rem.mileMarkersValidForRoute ?? true)
	);
};

export const selectAllowReopen = (state: RootState): boolean =>
	// allow users with permission to re-open valid, completed events from REM only
	userHasPermission(UserPermissions.REM_ACCESS) &&
	state.routing.page === AppSection.REM &&
	isREMDraftEvent(state.rem.draftEvent) &&
	state.rem.draftEvent.id !== undefined &&
	state.rem.draftEvent.eventStatus === EventStatus.COMPLETED;

export const selectHasRecipientsFromEmailGroupsRecipients = (state: RootState): boolean => {
	const groups = selectDraftEventByModule(state)?.emailGroupsRecipients?.emailGroups;
	const recipients = selectDraftEventByModule(state)?.emailGroupsRecipients?.emailRecipients;
	//	if either email groups or individual recipients have been added
	return (
		(groups !== undefined && groups.length > 0) ||
		(recipients !== undefined && recipients.length > 0)
	);
};

export const selectCountiesAsComboBoxOptions = (state: RootState): ComboBoxOption[] | undefined =>
	state.rem.eventFields?.counties?.map((value) => ({
		value,
	}));

export const selectDistrictsAsComboBoxOptions = (state: RootState): ComboBoxOption[] | undefined =>
	state.rem.eventFields?.districts?.map((value) => ({
		value,
	}));

export const selectHasValidLocationData = (state: RootState): boolean =>
	isValidString(selectDraftEventByModule(state)?.route) &&
	isValidNumber(selectDraftEventByModule(state)?.startMileMarker) &&
	selectDraftEventByModule(state)?.locationDetails !== undefined;

export const selectUnaddedEmailRecipientGroupsAsComboBoxOptions = (
	state: RootState,
): ComboBoxOption[] | undefined =>
	state.rem.emailGroupsRecipients?.emailGroups?.reduce(
		(options, existingEmailGroup): ComboBoxOption[] => {
			if (
				selectDraftEventByModule(state)?.emailGroupsRecipients?.emailGroups?.find(
					(draftEventEmailGroup) => draftEventEmailGroup.name === existingEmailGroup.name,
				) === undefined &&
				existingEmailGroup.name !== undefined
			) {
				options.push({
					value: existingEmailGroup.name,
				});
			}
			return options;
		},
		[] as ComboBoxOption[],
	);

export const signsForRemDmsPreview = createSelector(
	[(state: RootState): number[] | undefined => state.rem?.eventDMSSignLoadingIds, signsSelector],
	(loadingIds, signs): SignDto[] => {
		if (!loadingIds || !signs) {
			return [];
		}
		return loadingIds.reduce((acc, signId) => {
			const foundSign = signs.find((sign) => sign.id === signId);
			if (foundSign !== undefined) {
				acc.push(foundSign);
			}
			return acc;
		}, new Array<SignDto>());
	},
);

export const selectedSigns = createSelector(
	[
		(state: RootState): number[] | undefined => state.rem?.eventDMSSignLoadingIds,
		(state: RootState): PreviewDtoResponse | undefined => state.rem?.eventDMSPreviewMessages,
	],
	(eventDMSSignLoadingIds, eventDMSPreviewMessages): number[] => {
		return [
			...new Set([
				...(eventDMSPreviewMessages
					? Object.keys(eventDMSPreviewMessages).map((keyId) => parseInt(keyId, 10))
					: []),
				...(eventDMSSignLoadingIds || []),
			]),
		];
	},
);

export const selectedSignSelections = createSelector(
	[(state: RootState): PreviewDtoResponse | undefined => state.rem?.eventDMSPreviewMessages],
	(eventDMSPreviewMessages): SignSelection[] => {
		if (!eventDMSPreviewMessages) {
			return [];
		}
		return Object.entries(eventDMSPreviewMessages).reduce((acc, [key, previews]) => {
			const signId = parseInt(key, 10);
			if (previews?.length) {
				previews?.forEach((preview) => {
					acc.push({ signId, polarity: preview.polarity ?? Polarity.POSITIVE });
				});
			}
			return acc;
		}, [] as SignSelection[]);
	},
);

export const eventDMSPreviewMessagesWithErrors = createSelector(
	[
		signsSelector,
		(state: RootState): PreviewDtoResponse | undefined => state.rem?.eventDMSPreviewMessages,
	],
	(signs, eventDMSPreviewMessages): PreviewDtoResponse | undefined => {
		if (!eventDMSPreviewMessages) {
			return undefined;
		}
		return Object.entries(eventDMSPreviewMessages).reduce((acc, [key, previews]) => {
			const signId = parseInt(key, 10);
			if (previews?.length) {
				acc[key] = previews;
			} else {
				const sign = signs?.find((aSign) => aSign.id === signId);
				acc[key] = [{ signName: sign?.name, signId }];
			}
			return acc;
		}, {} as PreviewDtoResponse);
	},
);

export const selectHasSufficientDataForLocationDetails = (state: RootState): boolean =>
	isValidString(selectDraftEventByModule(state)?.route) &&
	numberIsValidAndFinite(selectDraftEventByModule(state)?.startMileMarker);

export const selectSignIdsFromEventDMSMessages = (state: RootState): number[] =>
	state.rem?.eventDMSPreviewMessages
		? Object.keys(state.rem.eventDMSPreviewMessages).map((previewMessageKey) =>
				parseInt(previewMessageKey, 10),
		  )
		: [];

export const selectAttributesAsEventAttributes = (state: RootState): EventAttribute[] | undefined =>
	selectDraftEventByModule(state)?.attributes?.map((attribute) => ({
		value: attribute.value,
		label: attribute.value,
	}));

export const selectDefaultPriorityForEventType = (state: RootState): number | undefined =>
	state.rem?.eventFields?.eventTypes?.find(
		(eventType) => eventType.name === state.rem?.draftEvent?.eventType,
	)?.defaultPriority?.priorityLevel;

export const selectUnitsMissingDispositions = (state: RootState): boolean =>
	!!state.rem?.draftEvent?.respondingUnits?.find(
		(unit) => unit.dispositions?.length === 0 || unit.dispositions === undefined,
	);

export const selectActiveEventsWithLatLon = createSelector(
	[(state: RootState): RoadEventDto[] | undefined => state.rem?.events],
	(events: RoadEventDto[] | undefined): RoadEventDto[] =>
		events?.filter(
			(e) =>
				e.eventStatus !== EventStatus.COMPLETED && isValidNumber(e.lat) && isValidNumber(e.lon),
		) ?? [],
);

export const selectEventById = (state: RootState, id: number): RoadEventDto | undefined =>
	state.rem.events?.find((e) => e.id === id);

export const selectDraftRouteHasLocation = (state: RootState): boolean =>
	state.rem?.eventFields?.routes?.find(
		(route) => route.routeIdentifier === selectDraftEventByModule(state)?.route,
	) !== undefined;

export const selectDraftEventHasExtraneousRoute = (state: RootState): boolean =>
	extraneousRouteOptions.find((route) => route.value === selectDraftEventByModule(state)?.route) !==
	undefined;

export const selectRouteIsExtraneous = (event: RoadEventDto): boolean =>
	extraneousRouteOptions.find((route) => route.value === event.route) !== undefined;

export const selectUserHasAccessToFilter = (
	user: AuthorityDto,
	filter: REMExternalFilterOptions,
): boolean => {
	if (
		userHasRole(user.roles, UserRoles['hoosier-helper']) &&
		!REMLiteTableFilters.includes(filter)
	) {
		return false;
	}

	return true;
};

export const selectREMQuickFilters = createSelector(
	[(state: RootState): AuthorityDto | undefined => state.user.authority],
	(user?: AuthorityDto): Record<string, REMExternalFilterOptions> => {
		const filters: Record<string, REMExternalFilterOptions> = {};

		Object.entries(REMExternalFilterOptions).forEach((entry) => {
			const [key, value] = entry;
			if (user && selectUserHasAccessToFilter(user, value)) {
				filters[key] = value;
			}
		});

		return filters;
	},
);

export const selectEventHeadlineAndDescription = (state: RootState): string[] => {
	const fullDescription = state.rem?.draftEventDescription;

	return fullDescription?.split('<br/>') ?? [];
};

export const selectLocationTypesToComboBoxOptions = (state: RootState): ComboBoxOption[] => {
	const locationTypeOptions: ComboBoxOption[] = [];

	state.rem?.eventFields?.eventLocationTypes?.forEach((locationType) => {
		if (locationType.active && locationType.name) {
			locationTypeOptions.push({ value: locationType.name, label: locationType.name });
		}
	});

	// locationTypeOptions.push({ value: 'GPS', label: 'GPS' });

	// locationTypeOptions.push({ value: 'AREA', label: 'AREA' });

	return locationTypeOptions;
};

export const selectNamedPointsToComboBoxOptions = (state: RootState): ComboBoxOption[] => {
	const route = state.rem?.draftEvent?.route;
	let namedPoints: NamedPointDto[] = [];
	const options: ComboBoxOption[] = [];

	if (route) {
		namedPoints =
			state.rem.namedPoints[route]?.filter((namedPoint) => isValidNumber(namedPoint.milePoint)) ??
			[];
	}

	if (namedPoints) {
		namedPoints.forEach((namedPoint) => {
			const { milePoint } = namedPoint;

			if (isValidNumber(milePoint)) {
				options.push({
					value: milePoint.toString(),
					label: namedPoint.name,
					disabled:
						milePoint === state.rem.draftEvent?.startMileMarker ||
						milePoint === state.rem.draftEvent?.endMileMarker,
				});
			}
		});
	}

	return options;
};

export const selectCitiesToComboBoxOptions = (state: RootState): ComboBoxOption[] => {
	const options: ComboBoxOption[] = [];
	state.rem.cities.forEach((city) => {
		if (isValidNumber(city.fips)) {
			options.push({
				value: city.fips.toString(),
				label: city.name,
			});
		}
	});

	return options;
};
export const selectNamedPointFromDraftEvent = (
	state: RootState,
	order: StartEnd,
): string | undefined => {
	const route = state.rem.draftEvent?.route;

	if (!route) {
		return undefined;
	}

	return state.rem.namedPoints[route]?.find(
		(namedPoint) =>
			Number(namedPoint.milePoint) ===
			(order === StartEnd.START
				? state.rem.draftEvent?.startMileMarker
				: state.rem.draftEvent?.endMileMarker),
	)?.name;
};

export const selectHeadlineAndPhrasesFromDraftEvent = (state: RootState): PhraseDto[] => {
	const draftEvent = selectDraftEventByModule(state);
	let phrases: PhraseDto[] = [];

	if (draftEvent && draftEvent.eventType) {
		const headlinePhraseDto: PhraseDto = {
			eventType: draftEvent.eventType,
			cause: false,
		};

		phrases = [headlinePhraseDto, ...(draftEvent.phrases ?? [])];
	}

	return phrases;
};

export const selectHasSufficientDataForDescription = (state: RootState): boolean => {
	const draftEvent = state.rem?.draftEvent;
	const eventType = draftEvent?.eventType;

	if (!eventType || EventTypeConfig[eventType]?.importTo511 === false) {
		return false;
	}

	const eventLocation = draftEvent?.eventLocation;

	if (eventLocation === LocationTypes.MAJOR_ROAD || eventLocation === null) {
		return (
			isValidString(draftEvent?.route) &&
			isValidNumber(draftEvent?.startMileMarker) &&
			draftEvent?.locationDetails?.lanes?.positive != null &&
			draftEvent?.locationDetails?.lanes?.negative != null
		);
	}
	if (eventLocation === LocationTypes.LOCAL_ROAD) {
		return (
			draftEvent?.locationDetails?.city !== undefined &&
			draftEvent?.locationDetails?.proximity !== undefined &&
			isValidString(draftEvent?.route) &&
			isValidString(draftEvent?.locationDetails?.crossStreetStart)
		);
	}
	if (eventLocation === LocationTypes.RAMP) {
		return true;
	}
	if (eventLocation === LocationTypes.AREA) {
		return true;
	}
	if (eventLocation === LocationTypes.GPS) {
		return true;
	}
	return false;
};

export const selectDoesDraftEventHaveSufficientDataFor511Import = (
	draftEvent?: DraftEvent,
): boolean => {
	const eventLocation = draftEvent?.eventLocation as LocationTypes | null | undefined;
	const eventType = draftEvent?.eventType;

	if (!eventType || EventTypeConfig[eventType]?.importTo511 === false) {
		return false;
	}

	if (eventLocation === LocationTypes.MAJOR_ROAD || eventLocation == null) {
		return isValidString(draftEvent?.route) && isValidNumber(draftEvent?.startMileMarker);
	}
	if (eventLocation === LocationTypes.LOCAL_ROAD) {
		return (
			draftEvent?.locationDetails?.city !== undefined &&
			draftEvent?.locationDetails?.proximity !== undefined &&
			isValidString(draftEvent?.route) &&
			isValidString(draftEvent?.locationDetails?.crossStreetStart)
		);
	}

	if (eventLocation === LocationTypes.RAMP) {
		return draftEvent?.eventType !== undefined;
	}

	if (eventLocation === LocationTypes.AREA) {
		// TODO
	}
	if (eventLocation === LocationTypes.GPS) {
		// TODO
	}

	return false;
};

export const selectHasSufficientDataFor511Import = (state: RootState): boolean => {
	const draftEvent = state.rem?.draftEvent;

	return selectDoesDraftEventHaveSufficientDataFor511Import(draftEvent);
};

export const selectAffectedDirectionsFromDraftEvent = (state: RootState): CardinalDir[] => {
	if (state.rem.draftEvent === undefined) return [];

	const directions: CardinalDir[] = [];
	const { positiveDirection, negativeDirection } = state.rem.draftEvent;

	if (positiveDirection) directions.push(positiveDirection as CardinalDir);
	if (negativeDirection) directions.push(negativeDirection as CardinalDir);

	return directions;
};

export const selectRoutesWithRampsToComboboxOptions = (state: RootState): ComboBoxOption[] => {
	return state.rem?.routesWithRamps.map((route) => ({ value: route, label: route }));
};

export const selectRampsToComboboxOptions = (state: RootState): ComboBoxOption[] => {
	const route = state.rem?.draftEvent?.route;

	if (route === undefined) return [];

	if (state.rem?.ramps[route] === undefined) return [];

	const options: ComboBoxOption[] = [];
	state.rem?.ramps[route].forEach((ramp) => {
		if (isValidNumber(ramp.rampId)) {
			options.push({
				value: ramp.rampId?.toString(),
				label: ramp.description,
			});
		}
	});
	return options;
};

export const selectQuantityTypesToComboBoxOptions = (state: RootState): ComboBoxOption[] => {
	const quantityOptions: ComboBoxOption[] = [];

	state.rem.eventFields?.quantityTypes?.forEach((quantityType) => {
		const { active, name, description } = quantityType;

		const eventHasQuantity =
			state.rem.draftEvent?.quantities?.find(
				(quantity: QuantityDto) => quantity.quantityType === name,
			) !== undefined;

		if (active && name && !eventHasQuantity) {
			quantityOptions.push({
				value: name,
				label: description?.toUpperCase(),
			});
		}
	});

	return quantityOptions;
};

export const selectQuantityTypeByName = (
	state: RootState,
	quantity?: string,
): QuantityTypeDto | undefined => {
	if (quantity === undefined) {
		return undefined;
	}
	return state.rem.eventFields?.quantityTypes?.find(
		(quantityType) => quantityType.name === quantity,
	);
};

export const selectDraftEventRamp = (state: RootState): RampDto | undefined => {
	if (!state.rem.draftEvent) return undefined;

	const { route, rampPk } = state.rem.draftEvent;

	if (route === undefined || rampPk === undefined) {
		return undefined;
	}

	return state.rem?.ramps?.[route]?.find((ramp) => ramp.rampId === rampPk);
};

export const selectRampById = (
	state: RootState,
	route: string,
	rampId: number,
): RampDto | undefined => {
	return state.rem?.ramps?.[route]?.find((ramp) => ramp.rampId === rampId);
};

const MAX_SECONDS_SINCE_LAST_NOTIFIED = 15;

export const selectCurrentEditorUsernames = createSelector(
	[
		(state: RootState): CurrentEditorsDto | undefined => state.rem?.currentEditors,
		(_state: RootState, eventId?: number) => eventId,
		(_state: RootState, _eventId?: number, sessionUUID?: string) => sessionUUID,
	],
	(currentEditors?: CurrentEditorsDto, eventId?: number, sessionUUID?: string): string[] => {
		if (typeof eventId !== 'number') return [];
		if (currentEditors?.roadwayEventId === eventId) {
			const now = Date.now();
			const usernames: Set<string> = new Set();

			currentEditors?.currentEditors?.forEach((editor) => {
				const currentSession = editor.sessionUUID === sessionUUID;
				const lessThanXold =
					editor.lastNotified && now - editor.lastNotified < MAX_SECONDS_SINCE_LAST_NOTIFIED * 1000;

				// this editor last checked into server less than 15 seconds ago
				if (!currentSession && lessThanXold) {
					usernames.add(editor.username as string);
				}
			});

			return Array.from(usernames);
		}
		return [];
	},
);

export const selectDraftEventMissingRestrictions = (state: RootState): string[] => {
	const draftEvent = state.rem?.draftEvent;

	if (!draftEvent || !draftEvent.eventType) {
		return [];
	}

	const eventPhrases: EventType[] = [
		...[draftEvent.eventType],
		...(draftEvent.phrases?.map((phrase) => phrase.eventType as EventType) ?? []),
	];

	return eventPhrases.filter(
		(phrase) =>
			EventTypeConfig[phrase]?.restriction &&
			!draftEvent.quantities?.some(
				(quantity) => quantity.quantityType === EventTypeConfig[phrase]?.restriction,
			),
	);
};
