import { css } from 'lit';
import { RampMeterDto } from '@typings/api';
import { BadgeType, MILLISECONDS_PER_SECOND } from '../constants';

export const rampMeterColumns = <const>[
	'rampName',
	'rampStatus',
	'rampRoute',
	'rampMileMarker',
	'rampDirection',
	'meteringPlan',
];

export const ConfigRampMeter = {
	Map: {
		//  Leaflet map will center on this point, at the provided zoom level
		//  https://leafletjs.com/reference-1.4.0.html#map-setview
		DefaultLat: 39.76632525654491,
		DefaultLon: -86.15615844726564,
		DefaultZoom: 12,
		MinZoom: 7,
		MaxZoom: 18,
		SpiderifyDistancePx: 10,
		SpiderifyLegThicknessPx: 3,
		SpiderifyLegColorNormal: 'rgba(var(--brand-grey-dark),0.6)',
		SpiderifyLegColorFocus: 'rgba(var(--brand-grey-dark),1)',
	},
	Table: {
		pagination: [5, 15, 50, 100],
		pollingDelayMs: 10 * MILLISECONDS_PER_SECOND,
		defaultColumns: [
			'rampName',
			'rampStatus',
			'rampRoute',
			'rampMileMarker',
			'rampDirection',
			'meteringPlan',
		] as typeof rampMeterColumns[number][],
	},
};

export const ConfigRampMeterApi = {
	getRampMetersEndpoint(): string {
		return 'rampmeters';
	},
	rampMeterPollingRate: 60 * MILLISECONDS_PER_SECOND,
};

enum RampStatuses {
	ON = 1,
	OFF = 2,
	IN_SERVICE = 3,
	OUT_OF_SERVICE = 4,
	UNAVAILABLE = 5,
	UNKNOWN = 6,
}

enum RampMeteringPlanNumbers {
	DARK = 1,
	RESTING_GREEN = 2,
	FIXED_RATE = 3,
	TRAFFIC_RESPONSE = 4,
	EMERGENCY_GREEN = 5,
	RED_FLASH = 17,
	YELLOW_FLASH = 18,
}

export const getRampMeteringPlanBadgeType = (ramp: RampMeterDto): BadgeType => {
	switch (ramp.meteringPlan) {
		case RampMeteringPlanNumbers.RED_FLASH:
			return BadgeType.DANGER;
		case RampMeteringPlanNumbers.DARK:
		case RampMeteringPlanNumbers.YELLOW_FLASH:
			return BadgeType.CAUTION;
		default:
			return BadgeType.GOOD;
	}
};

export const getRampMeterStatusName = (ramp: RampMeterDto): string => {
	switch (ramp.rampStatus) {
		case RampStatuses.ON:
			return 'On';
		case RampStatuses.OFF:
			return 'Off';
		case RampStatuses.IN_SERVICE:
			return 'In Service';
		case RampStatuses.OUT_OF_SERVICE:
			return 'Out of Service';
		case RampStatuses.UNAVAILABLE:
			return 'Unavailable';
		case RampStatuses.UNKNOWN:
			return 'Unknown';
		default:
			return 'None';
	}
};

export const getRampMeteringPlanName = (ramp: RampMeterDto): string => {
	switch (ramp.meteringPlan) {
		case RampMeteringPlanNumbers.DARK:
			return 'Dark';
		case RampMeteringPlanNumbers.RESTING_GREEN:
			return 'Resting Green';
		case RampMeteringPlanNumbers.FIXED_RATE:
			return 'Fixed Rate';
		case RampMeteringPlanNumbers.TRAFFIC_RESPONSE:
			return 'Traffic Response';
		case RampMeteringPlanNumbers.EMERGENCY_GREEN:
			return 'Emergency Green';
		case RampMeteringPlanNumbers.RED_FLASH:
			return 'Red Flash';
		case RampMeteringPlanNumbers.YELLOW_FLASH:
			return 'Yellow Flash';
		default:
			return 'None';
	}
};

export const getRampMeterStatusBadgeType = (ramp: RampMeterDto): BadgeType => {
	switch (ramp.rampStatus) {
		case RampStatuses.ON:
		case RampStatuses.IN_SERVICE:
			return BadgeType.GOOD;
		default:
			return BadgeType.DANGER;
	}
};

export const getRampIconColor = (ramp: RampMeterDto): string => {
	switch (ramp.rampStatus) {
		case RampStatuses.ON:
		case RampStatuses.IN_SERVICE:
			switch (ramp.meteringPlan) {
				case RampMeteringPlanNumbers.DARK:
				case RampMeteringPlanNumbers.YELLOW_FLASH:
					return 'yellow';
				default:
					return 'green';
			}
		default:
			return 'red';
	}
};
