import { css } from 'lit';
import { UserPermissions } from '../../typings/api';
import {
	AppSection,
	Breakpoint,
	DMSView,
	HHView,
	MILLISECONDS_PER_SECOND,
	MetricsView,
	Region,
	CCTVView,
	REMView,
	RampMeterView,
} from '../constants';
import { ConfigUserManagement } from './ConfigLogin';
import { MapRegion } from './config';

export enum APIHost {
	'LOCALHOST' = 'http://localhost:3000', //	local json-server for api response mocking
	'LOCAL_DEV' = 'http://localhost:8080/api/', //	local java server
	'DEV' = 'https://dev.irisx.org/api/',
	'TEST' = 'https://test.irisx.org/api/',
	'MA-STAGING' = 'https://api-carsx-ma.stage.carstest.org/api/',
	'PGC-STAGING' = 'https://api-carsx-pgc.stage.carstest.org/api/',
}

export interface PageConfig {
	// pattern matching for route, specific match cases can be found in redux-routing.ts
	route: string;
	// concatted with ConfigCARSx.AppTitle for page title displayed in browser.
	pageTitle: string;
	// slug for url
	pageID?: string;
	// optional default views configurable by device size breakpoints
	// e.g. routing to map on mobile and table on desktop by default
	defaultRouteSegments?: {
		view: {
			[key in Breakpoint]: string;
		};
	};
	// permission to check before rendering in navbar and allowing routing
	permission?: UserPermissions;
	// if false, no navbar icon or routing
	active?: boolean;
	// if false, won't render in navbar
	renderNavbar?: boolean;
	// icon to associate with route in navbar
	icon?: string;
	// optional list of subpages which will be displayed in a dropdown if provided
	subpages?: PageConfig[];
}
export const ConfigCARSx = {
	AppTitle: 'CARS-x', //  this will be used to populate the page title, e.g. 'AppTitle - PageTitle'

	Logo: {
		//  the logo is the first item of the left of the menu
		icon: 'logo.png',
		alt: 'Indiana Department of Transportation',
		href: '/', //  clicking the link goes here,
	},

	Pages: {
		[AppSection.LOGIN]: {
			route: '/login',
			pageTitle: 'Login',
			renderNavbar: false,
		},
		[AppSection.CCTV]: {
			route: '/cctv', //  pattern matching for the route
			pageID: 'cctv',
			defaultRouteSegments: {
				// if you go to cctv/lobby/ - omitting the view value - the app will default
				// to different paths depending on current layout type:
				view: {
					[Breakpoint.desktop]: 'table',
					[Breakpoint.mobile]: 'table',
				},
			},
			pageTitle: 'CCTV', //  concatted with AppTitle and used as the <title> displayed in the browser
			permission: UserPermissions.CCTV_ACCESS,
			active: true,
			renderNavbar: true,
			icon: 'img/cctv-topbar-icon.svg',
			subpages: [
				{
					pageTitle: 'Camera List',
					route: `/cctv/0/${CCTVView.table}`,
					permission: UserPermissions.CCTV_ACCESS,
				},
				{
					pageTitle: 'Camera Map',
					route: `/cctv/0/${CCTVView.map}`,
					permission: UserPermissions.CCTV_ACCESS,
				},
			],
		},
		[AppSection.DMS]: {
			route: '/dms',
			pageID: 'dms',
			defaultRouteSegments: {
				view: {
					[Breakpoint.desktop]: 'table',
					[Breakpoint.mobile]: 'table',
				},
			},
			pageTitle: 'DMS',
			permission: UserPermissions.DMS_ACCESS,
			active: true,
			renderNavbar: true,
			icon: 'img/icon-sign-fill-solid.svg',
			subpages: [
				{
					pageID: 'table',
					pageTitle: 'Sign List',
					route: `/dms/${DMSView.table}`,
					permission: UserPermissions.DMS_ACCESS,
				},
				{
					pageID: 'table',
					pageTitle: 'Sign Map',
					route: `/dms/${DMSView.map}`,
					permission: UserPermissions.DMS_ACCESS,
				},
				{
					pageID: 'groups',
					pageTitle: 'Sign Groups',
					route: `/dms/${DMSView.group}`,
					permission: UserPermissions.DMS_CAN_MANAGE_GROUPS,
				},
				{
					pageID: 'messages',
					pageTitle: 'Custom Messages',
					route: `/dms/${DMSView['custom-messages']}`,
					permission: UserPermissions.DMS_CAN_MANAGE_MESSAGES,
				},
				{
					pageID: 'images',
					pageTitle: 'Sign Graphics',
					route: `/dms/${DMSView['image-library']}`,
					permission: UserPermissions.DMS_CAN_MANAGE_GRAPHICS,
				},
			],
		},
		[AppSection.REM]: {
			route: '/rem',
			pageID: 'rem',
			defaultRouteSegments: {
				view: {
					[Breakpoint.desktop]: 'table',
					[Breakpoint.mobile]: 'table',
				},
			},
			pageTitle: 'REM',
			permission: UserPermissions.REM_ACCESS,
			active: true,
			renderNavbar: true,
			icon: 'img/icon-rem-fill-solid.svg',
			subpages: [
				{
					pageTitle: 'Incident List',
					route: `/rem/${REMView.table}`,
					permission: UserPermissions.REM_ACCESS,
				},
				{
					pageTitle: 'Incident Map',
					route: `/rem/${REMView.map}`,
					permission: UserPermissions.REM_ACCESS,
				},
			],
		},
		[AppSection.HH]: {
			route: '/highway-helper',
			pageID: 'highway-helper',
			defaultRouteSegments: {
				view: {
					[Breakpoint.desktop]: 'table',
					[Breakpoint.mobile]: 'table',
				},
			},
			pageTitle: 'Safety Service Patrol',
			permission: UserPermissions.HH_ACCESS,
			active: true,
			renderNavbar: true,
			icon: 'img/icon-helper-fill-solid-module.svg',
			subpages: [
				{
					pageID: 'ssp-patrol-tracker',
					pageTitle: 'Patrol Tracker',
					route: `/highway-helper/${HHView.table}`,
				},
				{
					pageID: 'ssp-admin-tool',
					pageTitle: 'SSP Admin',
					route: `/highway-helper/${HHView.sspAdmin}`,
					permission: UserPermissions.SSP_ADMIN_ACCESS,
				},
			],
		},
		[AppSection.METRICS]: {
			route: '/metrics',
			pageID: 'metrics',
			defaultRouteSegments: {
				view: {
					[Breakpoint.desktop]: 'events',
					[Breakpoint.mobile]: 'events',
				},
			},
			pageTitle: 'Metrics',
			permission: UserPermissions.METRICS_ACCESS,
			active: true,
			renderNavbar: true,
			icon: 'img/icon-metric-fill-solid-module.svg',
			subpages: [
				{
					pageID: 'metrics-events',
					pageTitle: 'Events',
					route: `/metrics/${MetricsView.events}`,
				},
				{
					pageID: 'metrics-helpers',
					pageTitle: 'Safety Service Patrol',
					route: `/metrics/${MetricsView.helpers}`,
				},
				{
					pageID: 'performance-dashboard',
					pageTitle: 'KPI Dashboard',
					route: `/metrics/${MetricsView.dashboard}`,
					active: true,
				},
			],
		},
		[AppSection.RAMP_METER]: {
			route: '/rampmeter',
			pageID: 'rampmeter',
			defaultRouteSegments: {
				view: {
					[Breakpoint.desktop]: 'table',
					[Breakpoint.mobile]: 'table',
				},
			},
			pageTitle: 'Ramp Meter',
			permission: UserPermissions.RAMP_METER_ACCESS,
			active: true,
			renderNavbar: true,
			icon: 'img/icon-ramp-meter-navigations.svg',
			subpages: [
				{
					pageTitle: 'Ramp Meter List',
					route: `/rampmeter/${RampMeterView.table}`,
					permission: UserPermissions.CCTV_ACCESS,
				},
				{
					pageTitle: 'Ramp Meter Map',
					route: `/rampmeter/${RampMeterView.map}`,
					permission: UserPermissions.CCTV_ACCESS,
				},
			],
		},
		[AppSection.USER_GROUPS]: {
			route: '/users',
			pageID: 'users',
			pageTitle: 'User Groups',
			renderNavbar: false,
			active: ConfigUserManagement.showManageUsersPage,
			permission: UserPermissions.USER_MANAGEMENT_ACCESS,
		},
	} as Record<AppSection, PageConfig>,
	defaultPage: AppSection.CCTV,
	Map: {
		//  map location search will prioritize results by proximity to this point:
		GeoSearch: {
			APIRoot: 'https://photon.komoot.io/api/',
			BBox: [-88.09776, 37.771742, -84.784579, 41.760592],
			BaseSearch: 'Indiana',
			ResultsLanguage: 'en',
			SearchLocation: { lat: 39.76632525654491, lon: -86.15615844726564 },
		},
		Regions: [
			//  Leaflet map will pan + zoom to fit this area in the map view
			//  https://leafletjs.com/reference-1.4.0.html#map-flytobounds
			{
				name: Region.CENTRAL,
				bounds: { NorthLat: 40, EastLon: -86, SouthLat: 39.5, WestLon: -86.5 },
			},
			{
				name: Region.NORTHWEST,
				bounds: {
					NorthLat: 41.749158,
					EastLon: -87.526292,
					SouthLat: 41.174395,
					WestLon: -86.848747,
				},
			},
			{
				name: Region.SOUTHERN,
				bounds: {
					NorthLat: 37.914615,
					EastLon: -84.88947,
					SouthLat: 39.137788,
					WestLon: -86.612867,
				},
			},
			{
				name: Region.SOUTHWEST,
				bounds: {
					NorthLat: 38.269401,
					EastLon: -87.367076,
					SouthLat: 37.916643,
					WestLon: -87.945329,
				},
			},
		] as MapRegion[],
	},
};
export default ConfigCARSx;

//	https://docs.mapbox.com/help/troubleshooting/migrate-legacy-static-tiles-api/
export const leafletConfig = {
	tileUrlTemplate:
		'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
	satelliteLayerId: 'mapbox/satellite-streets-v11',
	streetsLayerId: 'mapbox/streets-v11',
	tileLayerOptions: {
		id: 'mapbox/traffic-day-v2',
		minZoom: 0,
		maxZoom: 22,
		zoomOffset: -1,
		tileSize: 512,
		accessToken:
			'pk.eyJ1IjoiY2FzdGxlcm9ja2RldiIsImEiOiJjbGRhcmYzNjkwbHFmM3BxcDZzODY4NDQxIn0.0TjtzoZhCdOVZUNlrevDRg',
		attribution:
			'© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
	},
	scaleControlOptions: {
		metric: true,
		imperial: true,
	},
};

export const tableConfig = {
	paginationSizes: [5, 15, 50, 100],
	defaultPagination: 3,
};

const APIConfig = {
	relativeAPIPath: `${window.location.origin}/api/`,
	endpointURLBase: '/',
	requestTimeoutMs: 10 * MILLISECONDS_PER_SECOND,
	eventPreviewAPI:
		'https://intg-carsprogram-org.stage.carstest.org/events_v1/api/eventPreview/carsx',
};

export const DebuggingConfig = {
	showConsoleLogs: true,
};

if (window.location.hostname === 'chaos.irisx.org') {
	//	workaround for api domain rewriting for chaos server
	APIConfig.endpointURLBase = String('https://de').concat('v.irisx.org/api/');
} else {
	APIConfig.endpointURLBase =
		process.env.NODE_ENV === 'production'
			? //	in production, the site targets the api relative to its webroot
			  APIConfig.relativeAPIPath
			: //	in development, the site targets another instance of the backend somewhere (likely dev.irisx.org or test.irisx.org)
			  APIHost.DEV;
}

export { APIConfig };

export enum SVGIconPaths {
	INFO = 'img/event-type-icon-info.svg',
	PLACEHOLDER = 'img/icon-placeholder-fill-solid.svg',
}

export enum PNGIconPaths {
	INFO = 'img/event-type-icon-info.png',
	PLACEHOLDER = 'img/icon-placeholder-fill-solid.png',
}

export const DateOptions: Intl.DateTimeFormatOptions = {
	hour: '2-digit',
	minute: '2-digit',
	month: 'numeric',
	day: '2-digit',
	year: 'numeric',
	timeZoneName: 'short',
};

export const jwtKeyName = () => `${window.location.host}_CARSX_JWT`;
