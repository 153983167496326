import { css } from 'lit';
/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { GeoJsonGeometryTypes } from 'geojson';
import { ComboBoxOption } from 'cra-web-components/components/combo-box/combo-box';
import { isEventAbandonedVehicleWithValidTimestamps, isEventInRegion } from '@/utils/utils';
import { DMSStatusType, EventStatus, LaneImpactValue, RoadEventDto } from '../../typings/api';
import { EventAttribute } from '../components/rem/rem-attributes';
import {
	MILLISECONDS_PER_HOUR,
	MILLISECONDS_PER_SECOND,
	REM_EVENT_COLUMNS,
	Region,
	RelativeDateTime,
	RelativeDuration,
} from '../constants';

import { EventType, EventTypes } from './ConfigREMEventTypes';

// list of all REM event form section names
export const REM_FORM_SECTIONS = {
	DESCRIPTION: 'description',
	SOURCE: 'source',
	LOCATION: 'location',
	VEHICLES: 'vehicles',
	RESTRICTIONS: 'restrictions',
	DETOURS: 'detours',
	NOTES: 'notes',
	VISUAL_VERIFICATION: 'visual verification',
	SCHEDULING: 'scheduling',
	DMS: 'dms',
	RESPONDING_UNITS: 'responding units',
	ATTRIBUTES: 'attributes',
	LINKING: 'linking',
	NOTIFICATIONS: 'notifications',
	CAMERAS: 'cameras',
} as const;

export type FormSectionsRecord<T> = {
	[K in keyof typeof REM_FORM_SECTIONS]: T;
};

interface FormSectionConfigOptions {
	active: boolean;
	toggleable: boolean;
}

export const FormSectionConfig: FormSectionsRecord<FormSectionConfigOptions> = {
	DESCRIPTION: {
		active: true,
		toggleable: false,
	},
	SOURCE: {
		active: true,
		toggleable: false,
	},
	LOCATION: {
		active: true,
		toggleable: false,
	},
	VEHICLES: {
		active: true,
		toggleable: true,
	},
	RESTRICTIONS: {
		active: true,
		toggleable: false,
	},
	DETOURS: {
		active: true,
		toggleable: false,
	},
	NOTES: {
		active: true,
		toggleable: false,
	},
	VISUAL_VERIFICATION: {
		active: true,
		toggleable: false,
	},
	SCHEDULING: {
		active: true,
		toggleable: false,
	},
	DMS: {
		active: true,
		toggleable: true,
	},
	RESPONDING_UNITS: {
		active: true,
		toggleable: true,
	},
	ATTRIBUTES: {
		active: true,
		toggleable: false,
	},
	LINKING: {
		active: true,
		toggleable: false,
	},
	NOTIFICATIONS: {
		active: true,
		toggleable: false,
	},
	CAMERAS: {
		active: true,
		toggleable: false,
	},
};

export const ConfigREMMap = {
	//  Leaflet map will center on this point, at the provided zoom level
	//  https://leafletjs.com/reference-1.4.0.html#map-setview
	defaultCenter: {
		lat: 39.76632525654491,
		lng: -86.15615844726564,
	},
	defaultZoom: 12,
	MinZoom: 7,
	MaxZoom: 18,
	pollingDelayMs: 10 * MILLISECONDS_PER_SECOND,
	fitBoundsOptions: {
		padding: 30,
		maxZoom: 13,
	},
};

export enum REMExternalFilterOptions {
	ALL = 'All',
	AE = 'Active Events',
	SI = 'AVL SI',
	NW = 'AVL NW',
	CI = 'AVL CI',
	CM = 'CONST/MAINT',
	HW = 'High Water',
	CLOSED = 'Closed',
}

export const REMLiteTableFilters: REMExternalFilterOptions[] = [
	REMExternalFilterOptions.AE,
	REMExternalFilterOptions.SI,
	REMExternalFilterOptions.NW,
	REMExternalFilterOptions.CI,
];

interface ExternalFilterOptionsConfig {
	excludeCompletedEvents: boolean;
	includedEventTypes: EventType[];
	excludedEventTypes: EventType[];
	additionalEventChecks?: Array<(event: RoadEventDto) => boolean>;
}

export const ConfigREMTable = {
	heartbeatFrequencyMs: 3000,
	pagination: [15, 25, 50, 100],
	defaultPagination: 3,
	pollingDelayMs: 10 * MILLISECONDS_PER_SECOND,
	defaultColumns: [
		'respondingUnits',
		'vehicle',
		'timeArrived',
		'timeAssigned',
		'timeCompleted',
		'type',
		'id',
		'route',
		'milemarker',
		'direction',
		'county',
		'district',
		'dateCreated',
		'attributes',
		'lastUpdated',
	] as typeof REM_EVENT_COLUMNS[number][],
	externalFilters: {
		[REMExternalFilterOptions.ALL]: {
			excludeCompletedEvents: true,
			includedEventTypes: [],
			excludedEventTypes: [],
		},
		[REMExternalFilterOptions.AE]: {
			excludeCompletedEvents: true,
			includedEventTypes: [],
			excludedEventTypes: [EventTypes.HIGH_WATER, EventTypes.CONSTRUCTION, EventTypes.MAINTENANCE],
			additionalEventChecks: [
				(event: RoadEventDto) => !isEventAbandonedVehicleWithValidTimestamps(event),
			],
		},
		[REMExternalFilterOptions.SI]: {
			excludeCompletedEvents: true,
			includedEventTypes: [],
			excludedEventTypes: [],
			additionalEventChecks: [
				isEventAbandonedVehicleWithValidTimestamps,
				(event: RoadEventDto) => isEventInRegion(event, Region.SOUTHERN),
			],
		},
		[REMExternalFilterOptions.NW]: {
			excludeCompletedEvents: true,
			includedEventTypes: [],
			excludedEventTypes: [],
			additionalEventChecks: [
				isEventAbandonedVehicleWithValidTimestamps,
				(event: RoadEventDto) => isEventInRegion(event, Region.NORTHWEST),
			],
		},
		[REMExternalFilterOptions.CI]: {
			excludeCompletedEvents: true,
			includedEventTypes: [],
			excludedEventTypes: [],
			additionalEventChecks: [
				isEventAbandonedVehicleWithValidTimestamps,
				(event: RoadEventDto) => isEventInRegion(event, Region.CENTRAL),
			],
		},
		[REMExternalFilterOptions.CM]: {
			excludeCompletedEvents: true,
			includedEventTypes: [EventTypes.CONSTRUCTION, EventTypes.MAINTENANCE],
			excludedEventTypes: [],
		},
		[REMExternalFilterOptions.HW]: {
			excludeCompletedEvents: true,
			includedEventTypes: [EventTypes.HIGH_WATER],
			excludedEventTypes: [],
		},
		[REMExternalFilterOptions.CLOSED]: {
			excludeCompletedEvents: false,
			includedEventTypes: [],
			excludedEventTypes: [],
			additionalEventChecks: [(event: RoadEventDto) => event.eventStatus === EventStatus.COMPLETED],
		},
	} as Partial<Record<REMExternalFilterOptions, ExternalFilterOptionsConfig>>,
};
// Configures the URLS for each endpoint to be used by the REM module
export const ConfigREMApi = {
	fields: (): string => `events/fields`,
	email: (): string => `events/email`,
	deleteEmail: (): string => `events/email/delete`,
	events: (): string => `events`,
	event: (eventId: number): string => `events/${eventId}`,
	eventTimeline: (eventId: number): string => `events/${eventId}/timeline`,
	createOrUpdate: (): string => `events`,
	routesByCountyDistrict: (): string => `locations/route`,
	countyDistrict: (route_designator: string): string =>
		`locations/route/${encodeURIComponent(route_designator)}`,
	nearbyCameras: (route_designator: string): string =>
		`locations/route/${route_designator}/cameras`,
	dmsPreviewsByREMEvent: (): string => `sign/previews`,
	dmsSignsForEvent: (): string => `sign/save/previews`,
	getSignById: (signId: number): string => `sign/id/${signId}`,
	getSignQueueById: (signId: number, signType: DMSStatusType = DMSStatusType.ACTIVE): string =>
		`sign/queue/${signType}/${signId}`,
	setDMSforRemEvent: (): string => `/api/sign/save/messages`,
	getDMSForRemEvent: (eventId: number, signType?: DMSStatusType): string =>
		`sign/messages/${eventId}/${signType ?? ''}`,
	removeDMSByREMEvent: (signId: number): string => `sign/trash/${signId}`,
	currentEditors: (eventId: number): string => `/api/events/${eventId}/currentEditors`,
	prioritizeMessagesForEvent: (eventId: number): string => `/api/events/${eventId}/refresh`,
	routeExtent: (): string => `locations/geometry`,
	namedPoints: (route: string) => `locations/route/${encodeURIComponent(route)}/named-points`,
	areas: (areaType: string) => `locations/areas/${areaType}`,
	codedRoadway: (route: string) => `locations/route/${encodeURIComponent(route)}/coded-roadway`,
	eventIcon: (id: number) => `icons/events/${id}`,
	eventIconBase: () => `icons/events`,
	routesWithRamps: () => `locations/ramps/routes`,
	rampsForRoute: (route: string) => `locations/ramps/${encodeURIComponent(route)}`,
	snapToRoutePoint: () => `locations/route/snapToRoutePoint`,
	ramp: (route: string, rampId: number) => `locations/ramps/${encodeURIComponent(route)}/${rampId}`,
	duplicateEvent: (id: number) => `events/${id}/duplicate`,
	requestTimeoutMs: 30 * MILLISECONDS_PER_SECOND,
	signPollingRate: 60 * MILLISECONDS_PER_SECOND,
	dateFormat: 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
};

export const ConfigREMEventForm = {
	location: {
		/*
			<input pattern="" /> regex is slightly different than javascript
			equivalent pattern in javascript: /^(?:\d{1,3}(?:\.\d{1})?)$/gm
		*/
		mileMarkerPattern: '[0-9]{1,3}(?:\\.[0-9]{1})?',
		mileMarkerInvalidPattern: 'Value must be formatted "mmm.t"',
		mileMarkerValueFitted: 'Mile marker entry snapped to nearest valid value.',
		mileMarkerEndValueInvalid: 'Invalid end value removed.',
		displayDistrict: true,
		displaySubdistrict: true,
		displayUnit: true,
		displayLaneDiagram: true,
	},
	source: {
		// FIXME: consider using intl-tel-input npm package in the future
		phoneNumberRegex: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
		phoneNumberInvalid: 'Warning: phone number may not be formatted correctly',
		ordering: [
			'CCTV',
			'Patrol Encounter',
			'State Police',
			'Phone Call',
			'Radio Call',
			'Local Law Enforcement',
			'Customer Service Call Center',
			'Email',
			'Social Media',
			'TMC Staff',
			'TMC Dashboards',
		],
	},
	affectedLanes: {
		defaultLaneBlockageType: LaneImpactValue.NA,
	},
	vehicles: {
		defaultLicensePlateState: 'N/A',
		licensePlateNumberDelimitter: '###',
	},
	dms: {
		prioritizeMessagesToolTip:
			'Clicking the <span style="color: rgb(var(--brand-status-caution));">Refresh Queue</span> button will resort the message queues for all signs associated with this event. The message associated with this event will be displayed on the board unless there are any messages with a higher priority in the queue.',
		messagesCanBeCustomized: false,
	},
	//	scheduling formats
	wholeDatestampFormatOptions: {
		//	for displaying the current date and time in full
		hour: '2-digit',
		minute: '2-digit',
		month: 'short',
		day: '2-digit',
		year: 'numeric',
		timeZoneName: 'short',
	} as Intl.DateTimeFormatOptions,
	schedulingDateFormatOptions: {
		//	for populating the date selector inputs
	},
	schedulingTimeFormatOptions: {
		//	for populating the time selector inputs
		hour12: false,
		hour: '2-digit',
		minute: '2-digit',
	} as Intl.DateTimeFormatOptions,
	timelineDateFormatOptions: {
		//	for the daily timeline entry headlines
		month: 'short',
		day: '2-digit',
		year: 'numeric',
	} as Intl.DateTimeFormatOptions,
	timelineTimeFormatOptions: {
		//	for the time of the individual changes
		hour: '2-digit',
		minute: '2-digit',
		timeZoneName: 'short',
	} as Intl.DateTimeFormatOptions,
	dateAndTimePickerFormatOptions: {
		//	for displaying the current date and time in full for the datepicker input
		hour: '2-digit',
		minute: '2-digit',
		month: '2-digit',
		day: '2-digit',
		year: 'numeric',
		hour12: false,
	} as Intl.DateTimeFormatOptions,
	requiredFields: ['eventType', 'eventSource', 'route'] as Array<keyof RoadEventDto>,
	showFormSectionMenu: true,
};

export const RelativeDateTimeEntries = {
	[RelativeDateTime.IMMEDIATELY]: 'Immediately',
	[RelativeDateTime.TOMORROW]: 'Tomorrow',
	[RelativeDateTime.WEEK]: 'Next Week',
	[RelativeDateTime.MONTH]: 'Next Month',
};

export const RelativeDurationEntries = {
	[RelativeDuration.INDEFINITELY]: 'Indefinitely',
	[RelativeDuration.HOUR]: 'One Hour',
	[RelativeDuration.DAY]: 'One Day',
	[RelativeDuration.WEEK]: 'One Week',
};

export enum EventAttributeValue {
	'Work Zone' = 'Work Zone',
	'CMV' = 'CMV',
	'Hazmat' = 'Hazmat',
	'Blocking' = 'Blocking',
	'Comment Card' = 'Comment Card',
	'Narcan' = 'Narcan',
  'Secondary Incident' = 'Secondary Incident',
}

export const EventAttributes: EventAttribute[] = [
	{
		value: EventAttributeValue['Work Zone'],
		label: EventAttributeValue['Work Zone'],
	},
	{
		value: EventAttributeValue.CMV,
		label: EventAttributeValue.CMV,
	},
	{
		value: EventAttributeValue.Hazmat,
		label: EventAttributeValue.Hazmat,
	},
	{
		value: EventAttributeValue.Blocking,
		label: EventAttributeValue.Blocking,
	},
	{
		value: EventAttributeValue['Comment Card'],
		label: EventAttributeValue['Comment Card'],
	},
	{
		value: EventAttributeValue.Narcan,
		label: EventAttributeValue.Narcan,
	},
  {
    value: EventAttributeValue['Secondary Incident'],
    label: EventAttributeValue['Secondary Incident'],
  }
];

export const REMSignPollingDelayMs = 10 * MILLISECONDS_PER_SECOND;

//	if any of these props change, the location details need to be refreshed
export const DRAFT_PROP_PATHS_THAT_INVALIDATE_LOCATION_DETAILS = [
	'route',
	'startMileMarker',
	'endMileMarker',
	'county',
	'district',
];

export const DRAFT_PROP_PATHS_THAT_INVALIDATE_SIGNS = [
	'route',
	'startMileMarker',
	'endMileMarker',
	'positiveLaneBlockage.allLanesAffected',
	'negativeLaneBlockage.allLanesAffected',
];
//	if any of these properties change, the DMS previews need to be reloaded
export const DRAFT_PROP_PATHS_THAT_INVALIDATE_DMS_PREVIEW = [
	'dateEnd',
	'dateStart',
	'eventType',
	'phrases',
	'route',
	'startMileMarker',
	'endMileMarker',
	'positiveLaneBlockage',
	'negativeLaneBlockage',
	'positiveLaneBlockageType',
	'positiveLaneBlockage.lanesAffected',
	'positiveLaneBlockage.enteranceRampAffected',
	'positiveLaneBlockage.exitRampAffected',
	'positiveLaneBlockage.insideShoulderAffected',
	'positiveLaneBlockage.outsideShoulderAffected',
	'negativeLaneBlockageType',
	'negativeLaneBlockage.lanesAffected',
	'negativeLaneBlockage.enteranceRampAffected',
	'negativeLaneBlockage.exitRampAffected',
	'negativeLaneBlockage.insideShoulderAffected',
	'negativeLaneBlockage.outsideShoulderAffected',
];

// if any of these props change, the event description preview needs to be refreshed
export const DRAFT_PROP_PATHS_THAT_INVALIDATE_DESCRIPTION = [
	'eventType',
	'phrases',
	'route',
	'positiveLaneBlockage',
	'negativeLaneBlockage',
	'positiveLaneBlockageType',
	'positiveLaneBlockage.lanesAffected',
	'positiveLaneBlockage.enteranceRampAffected',
	'positiveLaneBlockage.exitRampAffected',
	'positiveLaneBlockage.insideShoulderAffected',
	'positiveLaneBlockage.outsideShoulderAffected',
	'negativeLaneBlockageType',
	'negativeLaneBlockage.lanesAffected',
	'negativeLaneBlockage.enteranceRampAffected',
	'negativeLaneBlockage.exitRampAffected',
	'negativeLaneBlockage.insideShoulderAffected',
	'negativeLaneBlockage.outsideShoulderAffected',
	'locationDetails',
	'locationDetails.city',
	'locationDetails.proximity',
	'locationDetails.crossStreetStart',
	'locationDetails.crossStreetEnd',
	'geometry',
	'additionalPublicText',
	'dateStart',
	'dateEnd',
	'quantities',
	'eventLocation',
	'positiveDirection',
	'negativeDirection',
	'rampPk',
	'detours',
];

// if any of these props change, priority should be recalculated
export const DRAFT_PROP_PATHS_THAT_INVALIDATE_PRIORITY = [
	'eventType',
	'locationDetails',
	'positiveLaneBlockage',
	'negativeLaneBlockage',
	'positiveLaneBlockageType',
	'positiveLaneBlockage.lanesAffected',
	'positiveLaneBlockage.enteranceRampAffected',
	'positiveLaneBlockage.exitRampAffected',
	'positiveLaneBlockage.insideShoulderAffected',
	'positiveLaneBlockage.outsideShoulderAffected',
	'negativeLaneBlockageType',
	'negativeLaneBlockage.lanesAffected',
	'negativeLaneBlockage.enteranceRampAffected',
	'negativeLaneBlockage.exitRampAffected',
	'negativeLaneBlockage.insideShoulderAffected',
	'negativeLaneBlockage.outsideShoulderAffected',
];

// if any of these props change, the event icon should be re-fetched
export const DRAFT_PROP_PATHS_THAT_INVALIDATE_ICON = [
	'eventType',
	'phrases',
	'eventLocation',
	'priorityLevel',
	'dateStart',
	'positiveLaneBlockage',
	'negativeLaneBlockage',
	'positiveLaneBlockageType',
	'positiveLaneBlockage.lanesAffected',
	'positiveLaneBlockage.enteranceRampAffected',
	'positiveLaneBlockage.exitRampAffected',
	'positiveLaneBlockage.insideShoulderAffected',
	'positiveLaneBlockage.outsideShoulderAffected',
	'negativeLaneBlockageType',
	'negativeLaneBlockage.lanesAffected',
	'negativeLaneBlockage.enteranceRampAffected',
	'negativeLaneBlockage.exitRampAffected',
	'negativeLaneBlockage.insideShoulderAffected',
	'negativeLaneBlockage.outsideShoulderAffected',
];

export const extraneousRouteOptions: ComboBoxOption[] = [
	{
		value: 'Other',
		label: 'Other',
	},
];

export const defaultPriorityValueFallback = 5;

export const CARS_EVENT_INTEGRATION_ENABLED = true;

export const iconSizeByEventPriority = (priority: number): string => {
	if (priority === 1) return 'large';
	if (priority === 2 || priority === 3) return 'medium';

	return 'small';
};

export const iconSizes: Record<string, number> = {
	small: 20,
	medium: 28,
	large: 44,
};

// if an event occurs more than this configured time in the future, display its future icon on maps
export const FUTURE_EVENT_ICON_THRESHOLD = 2 * MILLISECONDS_PER_HOUR;

// these are the columns which will be rendered in the REM table view
export const activeREMTableColumns: typeof REM_EVENT_COLUMNS[number][] = [
	'respondingUnits',
	'vehicle',
	'timeAssigned',
	'timeArrived',
	'timeCompleted',
	'type',
	'id',
	'route',
	'milemarker',
	'direction',
	'county',
	'district',
	'dateCreated',
	'attributes',
	'lastUpdated',
	'schedule',
	'lastUser',
	'lastUpdated',
	'display511',
	'signMessages',
];

export interface ClusterConfig {
	minZoom: number;
	maxZoom: number;
	maxClusterDistancePx?: number;
	minClusterMembers?: number;
	excludeEventsBy?: {
		geometryType?: [GeoJsonGeometryTypes, ...GeoJsonGeometryTypes[]];
		priority?: [number, ...number[]];
		// namespace?: [RegExp | string, ...(RegExp | string)[]];
	};
	iconSrc: (resultCount: number) => string;
	iconSizePx: {
		width: number;
		height: number;
	};
	iconZIndex: number;
}

const ZINDEX_HIGHER_FEATURE = 1000;

export const clusterConfigEvents: ClusterConfig[] = [
	{
		excludeEventsBy: {
			// geometryType: ['Polygon', 'MultiPolygon'],
			priority: [1],
			// namespace: ['WazeAlerts', 'WazeJams', 'INSEG'],
		},
		maxClusterDistancePx: 28,
		minClusterMembers: 2,
		minZoom: 0,
		maxZoom: 8,
		iconSrc: (resultCount: number): string => {
			const prefix = `/img/cluster/icon-cluster-camera-`;
			const postfix = `-solid.svg`;
			if (resultCount >= 99) {
				return `${prefix}99plus${postfix}`;
			}
			if (resultCount >= 50) {
				return `${prefix}50plus${postfix}`;
			}
			if (resultCount >= 20) {
				return `${prefix}20plus${postfix}`;
			}
			if (resultCount > 10) {
				return `${prefix}10plus${postfix}`;
			}
			return `${prefix}${resultCount}${postfix}`;
		},
		iconSizePx: {
			width: 32,
			height: 32,
		},
		iconZIndex: ZINDEX_HIGHER_FEATURE,
	},
];

export const STATE_COMBOBOX_OPTIONS: ComboBoxOption[] = [
	{ value: 'N/A', label: 'N/A' },
	{ value: 'IN', label: 'IN' },
	{ value: 'AL', label: 'AL' },
	{ value: 'AK', label: 'AK' },
	{ value: 'AZ', label: 'AZ' },
	{ value: 'AR', label: 'AR' },
	{ value: 'CA', label: 'CA' },
	{ value: 'CO', label: 'CO' },
	{ value: 'CT', label: 'CT' },
	{ value: 'DE', label: 'DE' },
	{ value: 'FL', label: 'FL' },
	{ value: 'GA', label: 'GA' },
	{ value: 'HI', label: 'HI' },
	{ value: 'ID', label: 'ID' },
	{ value: 'IL', label: 'IL' },
	{ value: 'IA', label: 'IA' },
	{ value: 'KS', label: 'KS' },
	{ value: 'KY', label: 'KY' },
	{ value: 'LA', label: 'LA' },
	{ value: 'ME', label: 'ME' },
	{ value: 'MD', label: 'MD' },
	{ value: 'MA', label: 'MA' },
	{ value: 'MI', label: 'MI' },
	{ value: 'MN', label: 'MN' },
	{ value: 'MS', label: 'MS' },
	{ value: 'MO', label: 'MO' },
	{ value: 'MT', label: 'MT' },
	{ value: 'NE', label: 'NE' },
	{ value: 'NV', label: 'NV' },
	{ value: 'NH', label: 'NH' },
	{ value: 'NJ', label: 'NJ' },
	{ value: 'NM', label: 'NM' },
	{ value: 'NY', label: 'NY' },
	{ value: 'NC', label: 'NC' },
	{ value: 'ND', label: 'ND' },
	{ value: 'OH', label: 'OH' },
	{ value: 'OK', label: 'OK' },
	{ value: 'OR', label: 'OR' },
	{ value: 'PA', label: 'PA' },
	{ value: 'RI', label: 'RI' },
	{ value: 'SC', label: 'SC' },
	{ value: 'SD', label: 'SD' },
	{ value: 'TN', label: 'TN' },
	{ value: 'TX', label: 'TX' },
	{ value: 'UT', label: 'UT' },
	{ value: 'VT', label: 'VT' },
	{ value: 'VA', label: 'VA' },
	{ value: 'WA', label: 'WA' },
	{ value: 'WV', label: 'WV' },
	{ value: 'WI', label: 'WI' },
	{ value: 'WY', label: 'WY' },
];
