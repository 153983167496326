import { css } from 'lit';
import { EventType } from '@/config/ConfigREMEventTypes';
import { DirectionsWaypoint } from '@mapbox/mapbox-sdk/services/directions';
import OverrideProps from '../src/utils/OverrideProps';
import { RequireAtLeastOne } from '../src/utils/RequireAtLeastOne';
import { components, operations } from './carsx-api.gen';

//	enums for DTOs

export enum EventStatus {
	COMPLETED = 'COMPLETED',
	NORMAL = 'NORMAL',
	EXPIRED = 'EXPIRED',
}

export enum Polarity {
	POSITIVE = 'POSITIVE',
	NEGATIVE = 'NEGATIVE',
}

export enum DMSMessageType {
	'EVENT' = 'EVENT',
	'CUSTOM_MESSAGE' = 'CUSTOM_MESSAGE',
	'TRAVEL_TIME' = 'TRAVEL_TIME',
	'TPIMS' = 'TPIMS',
}

export enum RecordStatus {
	ACTIVE = 'ACTIVE',
	DELETED = 'DELETED',
}

export enum CardinalDir {
	N = 'N',
	E = 'E',
	S = 'S',
	W = 'W',
}

export enum LaneImpactValue {
	'NA' = 'N/A',
	'SLOW' = 'SLOW',
	'CLOSED' = 'CLOSED',
}

export enum UserPermissions {
	CCTV_ACCESS = 'CCTV_ACCESS',
	CCTV_CAN_CAST = 'CCTV_CAN_CAST',
	CCTV_READONLY = 'CCTV_READONLY',
	CCTV_CAN_MANAGE_CAMERAS = 'CCTV_CAN_MANAGE_CAMERAS',
	CCTV_CAN_MANAGE_INVENTORY = 'CCTV_CAN_MANAGE_INVENTORY',
	DMS_ACCESS = 'DMS_ACCESS',
	DMS_ADMIN_ACCESS = 'DMS_ADMIN_ACCESS',
	DMS_CAN_MANAGE_GRAPHICS = 'DMS_CAN_MANAGE_GRAPHICS',
	DMS_CAN_MANAGE_GROUPS = 'DMS_CAN_MANAGE_GROUPS',
	DMS_CAN_MANAGE_MESSAGES = 'DMS_CAN_MANAGE_MESSAGES',
	DMS_CAN_MANAGE_QUEUE = 'DMS_CAN_MANAGE_QUEUE',
	DMS_CAN_MANAGE_SIGNS = 'DMS_CAN_MANAGE_SIGNS',
	DMS_READONLY = 'DMS_READONLY',
	HH_ACCESS = 'HH_ACCESS',
	HH_READONLY = 'HH_READONLY',
	RAMP_METER_ACCESS = 'RAMP_METER_ACCESS',
	REM_ACCESS = 'REM_ACCESS',
	REM_CAN_CREATE_EVENTS_LIMITED = 'REM_CAN_CREATE_EVENTS_LIMITED',
	REM_READONLY = 'REM_READONLY',
	REM_CAN_DUPLICATE_EVENTS = 'REM_CAN_DUPLICATE_EVENTS',
	METRICS_ACCESS = 'METRICS_ACCESS',
	USER_MANAGEMENT_ACCESS = 'USER_MANAGEMENT_ACCESS',
	SSP_ADMIN_ACCESS = 'SSP_ADMIN_ACCESS',
}

export enum UserRoles {
	'guest' = 'guest',
	'cctv' = 'cctv',
	'hoosier-helper' = 'hoosier-helper',
	'tmc-operator' = 'tmc-operator',
	'tmc-manager' = 'tmc-manager',
	'admin' = 'admin',
}

export enum DMSStatusType {
	'ACTIVE' = 'ACTIVE',
	'PENDING' = 'PENDING',
	'ENDED' = 'ENDED',
	'REPLACED' = 'REPLACED',
}

export enum DMSSignType {
	'TTS' = 'TTS',
	'DMS' = 'DMS',
	'TPIMS' = 'TPIMS',
	'VSL' = 'VSL',
	'PDMS' = 'PDMS',
}

export enum CCTVDeviceType {
	'CCTV' = 'CCTV',
}

export enum RouteClassification {
	INTERSTATE = 'interstate',
	STATE = 'state_route',
	US = 'us_route',
}
//	types representing API request + response payloads

/*
 Notes:
  - some exports are mere convenience, to refer directly to ThisDto as opposed to always pulling from components['schemas']['ThisDto']
	- all fields on generated API typings are marked as optional by default, so any required fields need to be marked explicitly in an interface that extends the generated type
	- some fields' values are constrained to known values, but enums are not properly generated that cover these values, and need to be manually added
	- some generated typings are wrong, allow for data types that actually never occur (e.g. undefined), or don't allow for data types that do occur (e.g. null) and need to be widened via OverrideProps
	- some DTOs require at least of several fields, so we need to use RequireAtLeastOne to enforce this
 */

type SignSelectionBase = components['schemas']['SignSelection'];
export interface SignSelection extends SignSelectionBase {
	signId: number; //	required
	polarity: Polarity | undefined;
}

type AttributeDtoBase = components['schemas']['AttributeDto'];
export interface AttributeDto extends AttributeDtoBase {
	id: number;
	value: string;
}

export type LaneBlockage = components['schemas']['LaneBlockage'];
export type LaneBlockageType = components['schemas']['LaneBlockageType'];
export type RoadEventTimelineDto = components['schemas']['RoadEventTimelineDto'];
export type ChangeSetItemApi = components['schemas']['ChangeSetItem'];
type TimelineEntryBase = components['schemas']['TimelineEntry'];
export interface TimelineEntry extends TimelineEntryBase {
	timestamp: number;
}

type ChangeSetItemBase = components['schemas']['ChangeSetItem'];
type ChangeSetItemMod = {
	//	value and previousValue could be a single value or a collection, depending on fieldName
	name: string;
	id: number;
	value?: unknown;
	previousValue?: unknown;
};
export type ChangeSetItem = OverrideProps<ChangeSetItemBase, ChangeSetItemMod>;

export type RoadEventFieldDto = components['schemas']['RoadEventFieldDto'];

export type EventPriorityType = components['schemas']['EventPriorityType'];
export type EventIconType = components['schemas']['EventIconType'];
export type EventLinkageDto = components['schemas']['EventLinkageDto'];
export type VehicleDto = RequireAtLeastOne<
	components['schemas']['VehicleDto'],
	'licensePlate' | 'description'
>;
export type RouteDto = components['schemas']['RouteDto'];

export enum FeedStatus {
	ONLINE = 'ONLINE',
	OFFLINE = 'OFFLINE',
	UNKNOWN = 'UNKNOWN',
}
type CameraDtoBase = components['schemas']['CameraDto'];

export interface CameraDto extends CameraDtoBase {
	//	required
	id: number;
	cameraNumber: number;
	name: string;
	description: string;
	lat: number;
	lon: number;
	feedStatus: FeedStatus;
}

export interface ModelDto {
	man?: string;
	mod?: string;
}

type ControllerDtoBase = components['schemas']['ControllerDto'];
interface ControllerDtoBaseRequired extends ControllerDtoBase {
	attachedCameras: CameraDto[];
	name: string;
}
interface ControllerDtoBaseTypeMod {
	attachedCameras: (CameraDto | null)[];
}
export type ControllerDto = OverrideProps<ControllerDtoBaseRequired, ControllerDtoBaseTypeMod>;

export type GroupDto = components['schemas']['GroupDto'];
export type CreateUpdateGroupDto = components['schemas']['CreateUpdateGroupDto'];

export type SignDtoBase = components['schemas']['SignDto'];
export interface SignDto extends SignDtoBase {
	groups: GroupDto[];
	id: number; //	required
	type: DMSSignType; //	required
	lat: number;
	lon: number;
	direction: CardinalDir;
}
type SignDtoNewMod = {
	//	not yet known
	id?: number;
	type?: DMSSignType;
};
export type SignDtoNew = OverrideProps<SignDto, SignDtoNewMod>;
export type DeviceDtoBase = components['schemas']['DeviceDto'];
export type CameraRoleDtoBase = components['schemas']['CameraRoleDto'];
export type HighwayHelperFieldDto = components['schemas']['HighwayHelperFieldDto'];
type HighwayHelperDtoBase = components['schemas']['HighwayHelperDto'];
export interface HighwayHelperDto extends HighwayHelperDtoBase {
	id: number;
	name: string;
	notes?: string; //	FIXME: temp until back end implements this
}
export type HighwayHelperTimelineDto = components['schemas']['HighwayHelperTimelineDto'];
export type LocationDto = components['schemas']['LocationDto'];
export type RespondingUnitDto = components['schemas']['RespondingUnitDto'];
export type RespondingUnitDispositionDto = components['schemas']['RespondingUnitDispositionDto'];
export type RespondingUnitDispositionType = components['schemas']['RespondingUnitDispositionType'];
export type NamedPointDto = components['schemas']['NamedPointDto'];
export type AreaDto = components['schemas']['AreaDto'];

type DispositionTypeDtoBase = components['schemas']['DispositionTypeDto'];
export interface DispositionTypeDto extends DispositionTypeDtoBase {
	name: string; //	required
	description: string;
}

export type RespondingUnitMember = components['schemas']['RespondingUnitMember'];
export type EmailGroupsRecipientsDto = components['schemas']['EmailGroupsRecipientsDto'];
export type EmailRecipientDto = components['schemas']['EmailRecipientDto'];
export type EmailGroupDto = components['schemas']['EmailGroupDto'];
export type SignQueueDto = components['schemas']['SignQueueDto'];

export type QueueState = 'SENT' | 'SENDING' | 'UNSENT' | 'ERROR';

export type MessageDto = components['schemas']['MessageWithMetadataDto'];

// export interface MessageDto extends MessageDtoBase {
// 	//	required properties
// 	startTime: number;
// 	messageType: DMSMessageType;
// }
type CreatePreviewDtoBase = components['schemas']['CreatePreviewDto'];
interface CreatePreviewDtoMod extends CreatePreviewDtoBase {
	eventType: string;
	route: string;
}
type CreatePreviewDtoOptional = {
	positiveLaneBlockage?: LaneBlockage | null;
	negativeLaneBlockage?: LaneBlockage | null;
	positiveLaneBlockageType?: LaneImpactValue | null;
	negativeLaneBlockageType?: LaneImpactValue | null;
	selectedSigns: SignSelection[] | null;
};

export type CreatePreviewDto = OverrideProps<CreatePreviewDtoMod, CreatePreviewDtoOptional>;

export type CustomMessageDto = components['schemas']['CustomMessageDto'];
type EventMessageDtoBase = components['schemas']['EventMessageDto'];
export interface EventMessageDto extends EventMessageDtoBase {
	status: DMSStatusType;
	polarity: Polarity;
}
export type DisplayedMessageDto = components['schemas']['DisplayedMessageDto'];

type PreviewDtoBase = components['schemas']['PreviewDto'];
export interface PreviewDto extends PreviewDtoBase {
	polarity: Polarity;
}
export type PreviewDtoResponse = { [key: string]: PreviewDto[] | null };
export type FrameDto = components['schemas']['FrameDto'];
type GraphicDtoBase = components['schemas']['GraphicDto'];
export interface GraphicDto extends GraphicDtoBase {
	//	enforce required fields
	title: string;
	image: string;
	delete: boolean;
}

type AuthorityDtoBase = components['schemas']['AuthorityDto'];
export interface AuthorityDto extends AuthorityDtoBase {
	permissions: UserPermissions[];
	roles: UserRoles[];
}
export type CurrentEditorsDto = components['schemas']['CurrentEditorsDto'];
type EditorNotificationDtoBase = components['schemas']['EditorNotificationDto'];
export interface EditorNotificationDto extends EditorNotificationDtoBase {
	username: string;
}
export type AreaOfInfluenceDto = components['schemas']['AreaOfInfluenceDto'];
export type SignFieldDto = components['schemas']['SignFieldDto'];
export type SignConfigDto = components['schemas']['SignConfigDto'];
export type ManufacturerDto = components['schemas']['SignManufacturerTypeDto'];

type RoadEventDtoBase = components['schemas']['RoadEventDto'];
interface RoadEventDtoModRequired extends RoadEventDtoBase {
	id: number;
	createdBy: string;
	updatedBy: string;
	created: number;
	updated: number;
	priorityLevel: number;
	lat: number;
	lon: number;
	startMileMarker: number;
	eventType: EventType;
	eventStatus: EventStatus;
	route: string;
	eventSource: string;
	locationDetails: LocationDto;
	vehicles: VehicleDto[];
	attributes: AttributeDto[];
	respondingUnits: RespondingUnitDto[];
	linkedEvents: EventLinkageDto[];
	emailGroupsRecipients: EmailGroupsRecipientsDto;
	emailEnabled: boolean;
	//	property for clientside convenience, doesn't ever show up in server version of dto
	nearbyCameras?: CameraDto[];
}
type RoadEventDtoModTyped = {
	//	edit to help backend deal with empty lane blockages
	positiveLaneBlockage?: LaneBlockage | null;
	negativeLaneBlockage?: LaneBlockage | null;
	positiveLaneBlockageType: LaneImpactValue;
	negativeLaneBlockageType: LaneImpactValue;
	// 	and to accomodate sign stuff
	selectedSigns: SignSelection[] | null;
};
export type RoadEventDto = OverrideProps<RoadEventDtoModRequired, RoadEventDtoModTyped>;

type RoadwayEventMessagesDtoBase = components['schemas']['RoadwayEventMessagesDto'];
interface RoadwayEventMessagesRequired extends RoadwayEventMessagesDtoBase {
	// 	required
	eventDto: RoadEventDtoBase;
	eventMessages: EventMessageDto[];
}
type RoadwayEventMessagesDtoTyped = {
	eventDto: RoadEventDto;
};
export type RoadwayEventMessagesDto = OverrideProps<
	RoadwayEventMessagesRequired,
	RoadwayEventMessagesDtoTyped
>;

export type DraftEvent = Partial<RoadEventDto>;

export type MetricsEventsSearchParams = operations['getCompletedEvents']['parameters']['query'];
export type MetricsHelpersSearchParams =
	operations['getActiveHighwayHelpers']['parameters']['query'];
export type MetricsEventsSearchResults = components['schemas']['MetricsEventSearchResultDto'];
export enum LocationTypes {
	MAJOR_ROAD = 'MAJOR ROAD',
	LOCAL_ROAD = 'LOCAL ROAD',
	RAMP = 'RAMP',
	AREA = 'AREA',
	GPS = 'GPS',
}
//	utility functions for generating 'empty' DTOs

export const getEmptyLaneBlockage = (): LaneBlockage => ({
	lanesAffected: [],
	entranceRampAffected: false,
	exitRampAffected: false,
	insideShoulderAffected: false,
	outsideShoulderAffected: false,
	allLanesAffected: false,
});

export const getEmptyDraftEvent = (): DraftEvent => ({
	eventType: undefined,
	eventSource: undefined,
	route: undefined,
	startMileMarker: undefined,
	eventLocation: LocationTypes.MAJOR_ROAD,
	dateStart: Date.now(),
	priorityLevel: 5,
});

export type PhraseDto = components['schemas']['PhraseDto'];

export type RampDto = components['schemas']['RampDto'];
export type RampMeterDto = components['schemas']['RampMeterDto'];

export type QuantityTypeDto = components['schemas']['QuantityType'];

export type QuantityDto = components['schemas']['QuantityDto'];

export enum Proximity {
	NEAR = 'NEAR',
	AT = 'AT',
}

export interface RoutePointDto {
	routePoint: GeoJSON.Point;
	routeIdentifier?: string;
	milePoint?: number;
}

export type SignMessageDto = components['schemas']['SignMessageDto'];

export enum CameraViewMode {
	MANUAL = 'MANUAL',
	HOOSIER_HELPER = 'HOOSIER_HELPER',
}

export enum CameraViewRegion {
	STATEWIDE = 'STATEWIDE',
	NORTH = 'NORTH',
	SOUTH = 'SOUTH',
	CENTRAL = 'CENTRAL',
}

// export type RoleWithUsersDto = components['schemas']['RoleWithUsersDto'];
// export type UserDto = components['schemas']['UserDto'];
// export type PermissionType = components['schemas']['PermissionTypeDto'];
// export type PermissionGroupType = components['schemas']['PermissionGroupWithPermissionsDto'];
// export type EditRoleDto = components['schemas']['EditRoleDto'];
// export type EditUserDto = components['schemas']['EditUserDto'];

export interface UserDto {
	id: number;
	email: string;
	username: string;
	firstName: string;
	lastName: string;
	lastLogin: number;
	roleIds: number[];
}
export interface RoleWithUsersDto {
	id: number;
	name: string;
	recordStatus: string;
	users: UserDto[];
	permissions: string[];
}
export interface PermissionType {
	name: string;
	description: string;
}
export interface PermissionGroupType {
	description: string;
	permissions: PermissionType[];
}
export interface EditRoleDto {
	id: number;
	name: string;
	permissions: string[];
	createdBy: string;
	updatedBy: string;
	created: number;
	updated: number;
}
export interface EditUserDto {
	id: number;
	email: string;
	username: string;
	password: string;
	firstName: string;
	lastName: string;
	roleIds: number[];

	createdBy: string;
	updatedBy: string;
	created: number;
	updated: number;
}

// DETOURS
export enum FlowDirection {
	ONE_WAY = 'ONE_WAY',
	TWO_WAY = 'TWO_WAY',
}

export type DetourDto = components['schemas']['DetourDto'];

export type DetourPointDto = components['schemas']['DetourPointDto'];

export type HighwayHelperUserDtoBase = components['schemas']['HighwayHelperUserDto'];
export interface HighwayHelperUserDto extends HighwayHelperUserDtoBase {
	id: number;
}
