import { css } from 'lit';
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Action } from 'redux';
import set from 'lodash-es/set';
import HTTPMethod from 'http-method-enum';
import { createSelector } from 'reselect';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from './APIRequest';
import { MonitorGroup } from '../../typings/shared-types';
import {
	CameraDto,
	CameraRoleDtoBase,
	CameraViewMode,
	ControllerDto,
	DeviceDtoBase,
	UserPermissions,
	UserRoles,
} from '../../typings/api';
import { RootState, ThunkActionRoot } from './redux-store';
import { ConfigCCTVApi, USER_CAN_TOGGLE_CAMERA_FEED } from '../config/ConfigCCTV';
import { APIConfig, DebuggingConfig } from '../config/ConfigCARSx';
import { CCTVCameraTypes, HttpStatusCode } from '../constants';
import { isValidNumber } from '../utils/utils';
import userHasPermission from './user-permissions';
import store from './redux-store';
import { CCTVActionType } from './cctv/cctv-actions';

const CAMERA_FILTERS_LOCAL_STORAGE_KEY: string = 'CARSx_cameraTypes';

export const getCameraTypesFromLocalStore = (): CCTVCameraTypes[] | null => {
	try {
		const localStoreRaw = window.localStorage.getItem(CAMERA_FILTERS_LOCAL_STORAGE_KEY);
		if (localStoreRaw) {
			const localStoreParsed = JSON.parse(localStoreRaw) as CCTVCameraTypes[];
			if (Array.isArray(localStoreParsed)) {
				return localStoreParsed;
			}
		}
	} catch (error) {
		if (DebuggingConfig.showConsoleLogs) {
			console.warn('unable to parse saved cameraTypes, defaulting to CCTV');
		}
	}
	return null;
};

export const allCameraTypeFilters = (): CCTVCameraTypes[] => {
	return Object.keys(CCTVCameraTypes) as CCTVCameraTypes[];
};

export const getCameraTypesFromLocalStoreAll = (): CCTVCameraTypes[] => {
	try {
		const localStoreRaw = window.localStorage.getItem(CAMERA_FILTERS_LOCAL_STORAGE_KEY);
		if (localStoreRaw) {
			const localStoreParsed = JSON.parse(localStoreRaw) as CCTVCameraTypes[];
			if (Array.isArray(localStoreParsed)) {
				return localStoreParsed;
			}
		}
	} catch (error) {
		if (DebuggingConfig.showConsoleLogs) {
			console.warn('unable to parse saved cameraTypes, defaulting to all type filters');
		}
	}
	const allFilters: CCTVCameraTypes[] = allCameraTypeFilters();
	store.getState().cctv.cameraTypes = allFilters;
	window.localStorage.setItem(CAMERA_FILTERS_LOCAL_STORAGE_KEY, JSON.stringify(allFilters));
	return allFilters;
};

//	STATE

export enum CCTVAPIError {
	GetMonitors = 'GetMonitors',
	SetMonitors = 'SetMonitors',
	LayoutMonitors = 'LayoutMonitors',
	GeoSearch = 'GeoSearch',
	GetCameras = 'GetCameras',
}

export enum MobileState {
	MONITORS = 'MONITORS',
	CAMERAS = 'CAMERAS',
	FILTERS = 'FILTERS',
}

export type CCTVState = {
	errors?: {
		[key in CCTVAPIError]?: {
			[key in APIError]?: boolean;
		};
	};
	currentMonitorGroup: MonitorGroup;
	monitors?: ControllerDto[];
	currentlySelectedGridIndex?: number;
	cameraTypes: CCTVCameraTypes[];
	cameras?: CameraDto[];
	mobileState?: MobileState;
	cameraRoles: CameraRoleDtoBase[];
	existingDevice?: DeviceDtoBase;
	loading?: boolean;
};

export const CCTV_STATE_INITIAL: CCTVState = {
	errors: {},
	currentMonitorGroup: MonitorGroup.MY_MONITORS,
	monitors: undefined,
	currentlySelectedGridIndex: undefined,
	cameras: undefined,
	mobileState: MobileState.MONITORS,
	cameraRoles: [],
	existingDevice: undefined,
	cameraTypes: getCameraTypesFromLocalStore() ?? [CCTVCameraTypes.FILTER_CCTV],
};

//	SELECTORS

//	'option' means "exclude this monitor from this user's personal list of monitors"
const filterIncludeRecommendedMonitorsOnly = (monitor: ControllerDto): boolean =>
	monitor.option === false;

export const selectMonitorsByGroup = (state: RootState): ControllerDto[] => {
	if (state.cctv.monitors === undefined) {
		return [];
	}
	const filteredMonitors =
		state.cctv.currentMonitorGroup === MonitorGroup.MY_MONITORS
			? state.cctv.monitors.filter(filterIncludeRecommendedMonitorsOnly)
			: state.cctv.monitors;
	const sortedMonitors = filteredMonitors.sort((a: ControllerDto, b: ControllerDto) => {
		if (a.displayName === undefined && b.displayName === undefined) {
			return 0;
		}
		if (a.displayName === undefined) {
			return 1;
		}
		if (b.displayName === undefined) {
			return -1;
		}
		return a.displayName.localeCompare(b.displayName, undefined, { numeric: true });
	});
	return sortedMonitors;
};

export const selectCurrentMonitor = (state: RootState): ControllerDto | undefined =>
	state.cctv.monitors?.find((monitor) => monitor.name === state.routing.group);

export const selectCurrentGridIndexCamId = (state: RootState): number | undefined =>
	isValidNumber(state.cctv.currentlySelectedGridIndex)
		? selectCurrentMonitor(state)?.attachedCameras?.[state.cctv.currentlySelectedGridIndex]?.id
		: undefined;

export const selectCastedCamIds = (state: RootState): number[] | undefined =>
	selectCurrentMonitor(state)?.attachedCameras?.reduce((camIds, camera) => {
		if (camera !== null) {
			camIds.push(camera.id);
		}
		return camIds;
	}, [] as number[]);

export const selectMonitorsSorted = (state: RootState): ControllerDto[] => {
	return (
		state.cctv.monitors?.sort((a: ControllerDto, b: ControllerDto) => {
			const displayNameA = a.displayName;
			const displayNameB = b.displayName;

			if (!displayNameA && !displayNameB) {
				return 0;
			}
			if (!displayNameA) {
				return -1;
			}
			if (!displayNameB) {
				return 1;
			}

			return displayNameA.localeCompare(displayNameB);
		}) ?? []
	);
};

export const selectUserCanManageCameras = (): boolean => {
	if (!USER_CAN_TOGGLE_CAMERA_FEED) return false;

	return userHasPermission(UserPermissions.CCTV_CAN_MANAGE_CAMERAS);
};

export const selectUserCanManageCameraInventory = (): boolean => {
	return userHasPermission(UserPermissions.CCTV_CAN_MANAGE_INVENTORY);
};

export const selectCameraById = (state: RootState, id: number): CameraDto | undefined =>
	state.cctv.cameras?.find((cam) => cam.id === id);

export const selectCamerasWithLatLon = createSelector(
	[(state: RootState): CameraDto[] => state.cctv.cameras ?? []],
	(cameras: CameraDto[]): CameraDto[] => {
		return cameras.filter((c) => c.lat !== null && c.lon !== null);
	},
);
//	ACTION TYPES

interface SetErrorState extends Action<typeof CCTVActionType.SET_ERROR_STATE> {
	source: CCTVAPIError;
	key: APIError;
	value: boolean;
}

interface SetCurrentMonitorGroup extends Action<typeof CCTVActionType.SET_CURRENT_MONITOR_GROUP> {
	currentMonitorGroup: MonitorGroup;
}

interface SetCameraRoles extends Action<typeof CCTVActionType.SET_CAMERA_ROLES> {
	cameraRoles: CameraRoleDtoBase[];
}

interface SetExistingDevice extends Action<typeof CCTVActionType.SET_EXISTING_DEVICE> {
	existingDevice: DeviceDtoBase;
}

type GetMonitors = Action<CCTVActionType.GET_MONITORS>;

interface StartPollingCCTVMonitors extends Action<typeof CCTVActionType.START_POLLING_MONITORS> {
	pollingDelay?: number;
}

type StopPollingCCTVMonitors = Action<typeof CCTVActionType.STOP_POLLING_MONITORS>;

interface SetMonitors extends Action<CCTVActionType.SET_MONITORS> {
	monitors: ControllerDto[];
}

interface SetCurrentGridContent extends Action<CCTVActionType.SET_CURRENT_GRID_CONTENT> {
	camId: number | null;
}

interface SetCurrentlySelectedGridIndex
	extends Action<typeof CCTVActionType.SET_CURRENTLY_SELECTED_GRID_INDEX> {
	currentlySelectedGridIndex?: number;
}

type GetCameras = Action<CCTVActionType.GET_CAMERAS>;

type GetCameraRoles = Action<CCTVActionType.GET_CAMERA_ROLES>;

interface SetCameraRoles extends Action<CCTVActionType.SET_CAMERA_ROLES> {
	cameraRoles: CameraRoleDtoBase[];
}

type GetExistingDevice = Action<CCTVActionType.GET_EXISTING_DEVICE>;

interface SetExistingDevice extends Action<CCTVActionType.SET_EXISTING_DEVICE> {
	existingDevice: DeviceDtoBase;
}

interface StartPollingCCTVCameras extends Action<typeof CCTVActionType.START_POLLING_CAMERAS> {
	cameraTypes?: CCTVCameraTypes;
	pollingDelay?: number;
}

type StopPollingCCTVCameras = Action<typeof CCTVActionType.STOP_POLLING_CAMERAS>;

interface SetCameras extends Action<CCTVActionType.SET_CAMERAS> {
	cameras: CameraDto[];
}

interface SetCamera extends Action<CCTVActionType.SET_CAMERA> {
	camera: CameraDto;
}

interface SetMonitor extends Action<CCTVActionType.SET_MONITOR> {
	monitor: ControllerDto;
}

interface SetMobileState extends Action<typeof CCTVActionType.SET_MOBILE_STATE> {
	mobileState: MobileState;
}

interface SetCCTVCameraTypes extends Action<typeof CCTVActionType.SET_CAMERA_TYPES> {
	cameraTypes: CCTVCameraTypes[];
	reflectToSessionStorage?: boolean;
	triggerCameraRefresh?: boolean;
}

export type CCTVAction =
	| SetErrorState
	| SetCurrentGridContent
	| SetCurrentMonitorGroup
	| SetCurrentlySelectedGridIndex
	| GetMonitors
	| StartPollingCCTVMonitors
	| StopPollingCCTVMonitors
	| SetMonitors
	| GetCameras
	| StartPollingCCTVCameras
	| StopPollingCCTVCameras
	| SetCameras
	| SetCamera
	| SetMonitor
	| SetMobileState
	| GetCameraRoles
	| SetCameraRoles
	| GetExistingDevice
	| SetExistingDevice
	| SetCCTVCameraTypes;

//	ACTIONS

export const setErrorState = (
	source: SetErrorState['source'],
	key: SetErrorState['key'],
	value: SetErrorState['value'],
): SetErrorState => ({
	type: CCTVActionType.SET_ERROR_STATE,
	source,
	key,
	value,
});

export const setExistingDevice = (existingDevice: DeviceDtoBase): SetExistingDevice => ({
	type: CCTVActionType.SET_EXISTING_DEVICE,
	existingDevice,
});

export const setCurrentMonitorGroup = (
	currentMonitorGroup: MonitorGroup,
): SetCurrentMonitorGroup => ({
	type: CCTVActionType.SET_CURRENT_MONITOR_GROUP,
	currentMonitorGroup,
});

export const setCurrentlySelectedGridIndex = (
	currentlySelectedGridIndex?: number,
): SetCurrentlySelectedGridIndex => ({
	type: CCTVActionType.SET_CURRENTLY_SELECTED_GRID_INDEX,
	currentlySelectedGridIndex,
});

export const getMonitors =
	(): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: CCTVActionType.GET_MONITORS,
		});
		const url = new URL(ConfigCCTVApi.getMonitorsEndpoint(), APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			APIConfig.requestTimeoutMs,
			true,
			false,
		);
		if (apiRequestReturn.response?.ok === true) {
			try {
				const monitors: ControllerDto[] =
					(await apiRequestReturn.response.json()) as ControllerDto[];
				dispatch({
					type: CCTVActionType.SET_MONITORS,
					monitors,
				});
			} catch (error) {
				apiRequestReturn.apiError = APIError.ResponseUnparseable;
				if (DebuggingConfig.showConsoleLogs) {
					console.error(
						`error parsing response from "${apiRequestReturn.request?.url as string}"`,
						error,
					);
				}
			}
		}
		return apiRequestReturn;
	};

export const getCameras =
	(
		cameraTypes: CCTVCameraTypes[] = getCameraTypesFromLocalStoreAll(),
		roles: UserRoles[] = store.getState().user.authority?.roles ?? [],
	): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: CCTVActionType.GET_CAMERAS });
		//  append new entry for each item
		const url = new URL(ConfigCCTVApi.getCamerasEndpoint(), APIConfig.endpointURLBase);
		if (cameraTypes) {
			Object.values(cameraTypes).forEach((type: string) =>
				url.searchParams.append('cameraTypes', type),
			);
		}
		if (roles) {
			Object.values(roles).forEach((role: string) =>
				url.searchParams.append('userRoleNames', role),
			);
		}
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);
		if (apiRequestReturn.response?.ok === true) {
			try {
				const cameras: CameraDto[] = (await apiRequestReturn.response.json()) as CameraDto[];
				dispatch({
					type: CCTVActionType.SET_CAMERAS,
					cameras,
				});
			} catch (error) {
				apiRequestReturn.apiError = APIError.ResponseUnparseable;
				if (DebuggingConfig.showConsoleLogs) {
					console.error(
						`error parsing response from "${apiRequestReturn.request?.url as string}"`,
						error,
					);
				}
			}
		}

		return apiRequestReturn;
	};

// AKA cast-a-cam
export const setCurrentGridContent =
	(camId?: number): ThunkActionRoot<Promise<APIRequestReturn | undefined>> =>
	async (dispatch, getState): Promise<APIRequestReturn | undefined> => {
		dispatch({
			type: CCTVActionType.SET_CURRENT_GRID_CONTENT,
			camId,
		});

		const state = getState();
		const { group } = state.routing;
		const currentMonitor = selectCurrentMonitor(state);
		const { currentlySelectedGridIndex } = state.cctv;
		if (!group || !currentMonitor) {
			//	ideally no-op
			if (DebuggingConfig.showConsoleLogs) {
				console.error(
					`no group set in routing state, unable to cast camId "${
						camId ?? 'unspecified'
					}" to unknown monitor`,
				);
			}
			return undefined;
		}
		if (currentlySelectedGridIndex === undefined) {
			//	ideally no-op
			if (DebuggingConfig.showConsoleLogs) {
				console.error(
					`no currently selected grid index in state to cast camId "${camId ?? 'unspecified'}" to`,
				);
			}
			return undefined;
		}

		const attachedCameras = currentMonitor.attachedCameras.map((cam, index) => {
			if (cam === null && index !== currentlySelectedGridIndex) {
				return null;
			}

			return {
				viewMode:
					index === currentlySelectedGridIndex
						? CameraViewMode.MANUAL
						: cam?.viewMode ?? CameraViewMode.MANUAL,
				viewOptions: {
					CAMERA_ID: index === currentlySelectedGridIndex ? camId : cam?.id ?? null,
					REGION: cam?.region,
				},
			};
		});

		const apiRequestReturn = await APIRequest(
			new Request(
				new URL(ConfigCCTVApi.getSetMonitorEndpoint(group), APIConfig.endpointURLBase).href,
				{
					method: HTTPMethod.POST,
					headers: new Headers({
						...getAPIHeaders(),
					}),
					body: JSON.stringify({ attachedCameras }),
				},
			),
		);

		if (apiRequestReturn.response?.ok === true) {
			try {
				await dispatch(getMonitors());
			} catch (error) {
				apiRequestReturn.apiError = APIError.ResponseUnparseable;
				if (DebuggingConfig.showConsoleLogs) {
					console.error(
						`error parsing response from "${apiRequestReturn.request?.url as string}"`,
						error,
					);
				}
			}
		}
		return apiRequestReturn;
	};

//	POLLING

let pollingCCTVMonitorsTimeoutId: ReturnType<typeof setInterval>;
let lastMonitorsAPIRequestReturn: APIRequestReturn;

export const stopPollingCCTVMonitors = (autoAbort = false): void => {
	window.clearInterval(pollingCCTVMonitorsTimeoutId);

	if (autoAbort && lastMonitorsAPIRequestReturn) {
		lastMonitorsAPIRequestReturn.abortController?.abort();
	}
};

export const startPollingCCTVMonitors =
	(
		pollingDelay = ConfigCCTVApi.monitorsPollingRate,
		pollImmediately = true,
	): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		if (pollImmediately) {
			lastMonitorsAPIRequestReturn = await dispatch(getMonitors());
		}
		window.clearInterval(pollingCCTVMonitorsTimeoutId);
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		pollingCCTVMonitorsTimeoutId = setInterval(async (): Promise<void> => {
			lastMonitorsAPIRequestReturn = await dispatch(getMonitors());

			if (lastMonitorsAPIRequestReturn.response?.status === HttpStatusCode.UNAUTHORIZED) {
				stopPollingCCTVMonitors();
			}
		}, pollingDelay);
	};

let pollingCCTVCamerasTimeout: ReturnType<typeof setInterval>;
let lastCamerasAPIRequestReturn: APIRequestReturn;

export const stopPollingCCTVCameras = (autoAbort = false): void => {
	window.clearInterval(pollingCCTVCamerasTimeout);

	if (autoAbort && lastCamerasAPIRequestReturn) {
		lastCamerasAPIRequestReturn.abortController?.abort();
	}
};

export const startPollingCCTVCameras =
	(
		pollingDelay = ConfigCCTVApi.camerasPollingRate,
		pollImmediately = true,
	): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		if (pollImmediately) {
			lastCamerasAPIRequestReturn = await dispatch(
				getCameras(getCameraTypesFromLocalStoreAll(), store.getState().user.authority?.roles),
			);
		}
		window.clearInterval(pollingCCTVCamerasTimeout);
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		pollingCCTVCamerasTimeout = setInterval(async (): Promise<void> => {
			lastCamerasAPIRequestReturn = await dispatch(
				getCameras(getCameraTypesFromLocalStoreAll(), store.getState().user.authority?.roles),
			);
			if (lastCamerasAPIRequestReturn.response?.status === HttpStatusCode.UNAUTHORIZED) {
				stopPollingCCTVCameras();
			}
		}, pollingDelay);
	};

export const setMobileState = (mobileState: MobileState): SetMobileState => ({
	type: CCTVActionType.SET_MOBILE_STATE,
	mobileState,
});

export const setCCTVCameraTypes =
	(
		cameraTypes: CCTVCameraTypes[],
		reflectToSessionStorage = true,
		triggerCameraRefresh = true,
	): ThunkActionRoot<SetCCTVCameraTypes> =>
	(dispatch): SetCCTVCameraTypes => {
		if (reflectToSessionStorage) {
			const cleanedCameraTypes: CCTVCameraTypes[] = [];
			for (let i = 0; i < cameraTypes.length; i++) {
				if (Object.keys(CCTVCameraTypes).includes(cameraTypes[i])) {
					cleanedCameraTypes.push(cameraTypes[i]);
				}
			}
			window.localStorage.setItem(
				CAMERA_FILTERS_LOCAL_STORAGE_KEY,
				JSON.stringify(cleanedCameraTypes),
			);
		}
		if (triggerCameraRefresh) {
			void dispatch(getCameras(cameraTypes, store.getState().user.authority?.roles));
		}
		return dispatch({
			type: CCTVActionType.SET_CAMERA_TYPES,
			cameraTypes,
		});
	};

//	REDUCER

export const CCTVReducer = (
	state: CCTVState = CCTV_STATE_INITIAL,
	action: CCTVAction | undefined = undefined,
): CCTVState => {
	if (action === undefined) {
		return state;
	}
	switch (action.type) {
		case CCTVActionType.SET_ERROR_STATE: {
			return {
				...state,
				errors: set(state.errors ?? {}, `${action.source}.${action.key}`, action.value),
			};
		}
		case CCTVActionType.SET_CURRENT_MONITOR_GROUP:
			return {
				...state,
				currentMonitorGroup: action.currentMonitorGroup,
			};
		case CCTVActionType.SET_CURRENTLY_SELECTED_GRID_INDEX:
			return {
				...state,
				currentlySelectedGridIndex: action.currentlySelectedGridIndex,
			};
		case CCTVActionType.SET_MONITORS:
			return {
				...state,
				monitors: action.monitors,
			};
		case CCTVActionType.SET_CAMERAS:
			return {
				...state,
				cameras: action.cameras,
			};
		case CCTVActionType.SET_CAMERA_ROLES:
			return {
				...state,
				cameraRoles: action.cameraRoles,
			};
		case CCTVActionType.SET_EXISTING_DEVICE:
			return {
				...state,
				existingDevice: action.existingDevice,
			};
		case CCTVActionType.SET_CAMERA:
			return {
				...state,
				cameras: [
					...(state.cameras?.filter((c) => c.id !== action.camera.id) ?? []),
					action.camera,
				],
			};
		case CCTVActionType.SET_MONITOR:
			return {
				...state,
				monitors: [
					...(state.monitors?.filter((c) => c.name !== action.monitor.name) ?? []),
					action.monitor,
				],
			};
		case CCTVActionType.SET_MOBILE_STATE:
			return {
				...state,
				mobileState: action.mobileState,
			};
		case CCTVActionType.SET_CAMERA_TYPES:
			return {
				...state,
				cameraTypes: action.cameraTypes,
			};
		default:
			return state;
	}
};
